import React,{useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap';
import  Card  from '../../../components/Card'
import Chart from "react-apexcharts";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay  } from 'swiper';
import 'swiper/swiper-bundle.css';
import Select from 'react-select'

// img
import dash01 from '../../../assets/images/dashboard/01.jpg'
import dash02 from '../../../assets/images/dashboard/02.jpg'
import dash03 from '../../../assets/images/dashboard/03.jpg'
import dash04 from '../../../assets/images/dashboard/04.jpg'
import dash05 from '../../../assets/images/dashboard/05.jpg'
import dash06 from '../../../assets/images/dashboard/06.jpg'

import mt01 from '../../../assets/images/movie-thumb/01.jpg'
import mt04 from '../../../assets/images/movie-thumb/04.jpg'
import mt05 from '../../../assets/images/movie-thumb/05.jpg'
import mt07 from '../../../assets/images/movie-thumb/07.jpg'


import MovieTable from '../../../database/table/movie'
import Swal from 'sweetalert2'
import axios from 'axios'

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

const TableMovie = new MovieTable()
const url = process.env.REACT_APP_URL

const Dashbord = () => { 

   const [movies,setMovies] = useState([])

   useEffect( () =>{
      TableMovie.get().then(res => {

          //foreach res.desactors, json.parse and convert to string
          res.map(item => {
            //json decode item.desactors and convert desactor.label to string separated with ","
            item.desactors = JSON.parse(item.desactors).map(desactor => desactor.label).join(', ')
        })

         setMovies(res)
      })
   }, []);
      
     
   const options1 = [
      { value: 'today', label: 'Today' },
      { value: 'this month', label: 'This Month' },
      { value: 'this week', label: 'This Week' }
   ]

   const options2 = [
      { value: 'Most Likely', label: 'Most Likely' },
      { value: 'Unlikely', label: 'Unlikely' }
   ]

   const chart1={
      options:{
         chart: {
            id: "view-chart-01",
            },
         colors:['#e20e02', '#f68a04', '#007aff','#545e75'],
         labels: ["New Customer", "Exsisting Subscriber's", "Daily Visitor's", "Extented Subscriber's"],
         dataLabels: {
            enabled: false
       },
       stroke: {
           show: false,
           width: 0
       },
       legend: {
           show: false,
       },
       responsive: [{
         breakpoint: 480,
         options: {
           chart: {
             width: 200
           },
           legend: {
             position: 'bottom'
           }
         }
       }]
      },
       series: [44, 55, 30, 30],
   }
   const chart2={
         options : {
       colors:['#e20e02', '#007aff'],
         chart: {
         id:"view-chart-03",
         foreColor: '#D1D0CF'
       },
       plotOptions: {
         bar: {
           horizontal: false,
           columnWidth: '55%',
           endingShape: 'rounded'
         },
       },
       dataLabels: {
         enabled: false
       },
       stroke: {
         show: true,
         width: 2,
         colors: ['transparent']
       },
       xaxis: {
         categories: ['a','b','c','d'],
       },
       yaxis: {
         title: {
           text: ''
         }
       },
       fill: {
         opacity: 1
       },
       tooltip: {
           enabled: false,
         y: {
           formatter: function (val) {
             return "$ " + val + " thousands"
           }
         }
       }
       },
       series: [{
         name: 'This Month',
         data: [44, 55,30,60]
       }, {
         name: 'Last Month',
         data: [35, 41,20,40]
       }],
   }
   const chart3={
         options : {
         chart: {
         id:"view-chart-02",
       },
       colors:['#e20e02','#83878a', '#007aff','#f68a04', '#14e788','#545e75'],
       labels: ['Actions', 'Comedy', 'Harror', 'Drama', 'Kids','Thrilled'],
       dataLabels: {
         enabled: false
       },
       stroke: {
           show: false,
           width: 0
       },
       legend: {
           show: false,
         formatter: function(val, opts) {
           return val + " - " + opts.w.globals.series[opts.seriesIndex]
         }
       },
       responsive: [{
         breakpoint: 480,
         options: {
           chart: {
             width: 200
           },
           legend: {
             position: 'bottom'
           }
         }
       }]
       },
       series: [44, 30, 20, 43, 22,20],
   }


  
   
   //if table already loaded then destroy it
   if ($.fn.DataTable.isDataTable("#table-filco")) {
      $("#table-filco").DataTable().destroy();
   }


   if (movies.length > 0) {
      console.log({
            data: movies
      })
      const table = $("#table-filco").DataTable({
            data: movies,
            columns: [
               { data: "id" },
               { data: "desactors" },
               { data: "desdescription" },
               { data: "dtregister" },
            ],
            //style last column
            columnDefs: [
               {
                  targets: 0,
                  render: function (data, type, row) {
                     return `<div class="media align-items-center">
                                 <div class="iq-movie">
                                    <a href="#"><img src='${url}/api/user/profile/${row.desimage}' class="img-border-radius avatar-40 img-fluid" alt=""/></a>
                                 </div>
                                 <div class="media-body text-white text-left ml-3">
                                    <p class="mb-0">${row.desname}</p>
                                    <small>1h 40m</small>
                                 </div>
                              </div>`;
                  },
               },
               {
                  targets: 2,
                  render: function (data, type, row) {
                     //se a descricao for maior que 50 caracteres, entao corta e adiciona ...
                     if (data.length > 20) {
                         return data.substr(0, 20) + "...";
                     }
                     return data;
                  }
              },
            ],
            //limit a 5 por pagina
            pageLength: 5,
            //hide "shoe entries button"
            lengthChange: false,
            //change position of "search" input
            dom: 'lrtip',
      });
      // #column3_search is a <input type="text"> element
      $('#input-search-data').on( 'keyup', function () {
            //saerch from table by name in all columns
            table.search( this.value ).draw();
      } );
   }












    return (
       <> 
        <Container fluid>
           <Row>
              <Col lg="12">
                 <Row>
                    <Col sm="6" lg="6" xl="3">
                       <Card className="iq-card-block iq-card-stretch iq-card-height">
                          <Card.Body>
                             <div className="d-flex align-items-center justify-content-between">
                                <div className="iq-cart-text text-capitalize">
                                   <p className="mb-0">
                                      Total de Visualizações
                                   </p>
                                </div>
                                <div className="icon iq-icon-box-top rounded-circle bg-primary">
                                   <i className="las la-eye"></i>
                                </div>
                             </div>
                             <div className="d-flex align-items-center justify-content-between mt-3">
                                <h4 className=" mb-0">+0</h4>
                                <p className="mb-0 text-primary"><span><i className="fa fa-caret-down mr-2"></i></span>0%</p>
                             </div>
                          </Card.Body>
                       </Card>
                    </Col>
                    <Col sm="6" lg="6" xl="3">
                       <Card className="iq-card-block iq-card-stretch iq-card-height">
                          <Card.Body>
                             <div className="d-flex align-items-center justify-content-between">
                                <div className="iq-cart-text text-capitalize">
                                   <p className="mb-0 font-size-14">
                                      Novos Usuários
                                   </p>
                                </div>
                                <div className="icon iq-icon-box-top rounded-circle bg-warning">
                                   <i className="lar la-star"></i>
                                </div>
                             </div>
                             <div className="d-flex align-items-center justify-content-between mt-3">
                                <h4 className="mb-0">+0</h4>
                                <p className="mb-0 text-warning"><span><i className="fa fa-caret-up mr-2"></i></span>0%</p>
                             </div>
                          </Card.Body>
                       </Card>
                    </Col>
                    <Col sm="6" lg="6" xl="3">
                       <Card className="iq-card-block iq-card-stretch iq-card-height">
                          <Card.Body>
                             <div className="d-flex align-items-center justify-content-between">
                                <div className="iq-cart-text text-capitalize">
                                   <p className="mb-0 font-size-14">
                                      Novos Visitantes
                                   </p>
                                </div>
                                <div className="icon iq-icon-box-top rounded-circle bg-success">
                                   <i className="lar la-user"></i>
                                </div>
                             </div>
                             <div className="d-flex align-items-center justify-content-between mt-3">
                                <h4 className="mb-0">+0</h4>
                                <p className="mb-0 text-success"><span><i className="fa fa-caret-up mr-2"></i></span>0%</p>
                             </div>
                          </Card.Body>
                       </Card>
                    </Col>
                    <Col sm="6" lg="6" xl="3">
                       <Card className="iq-card-block iq-card-stretch iq-card-height">
                          <Card.Body>
                             <div className="d-flex align-items-center justify-content-between">
                                <div className="iq-cart-text text-capitalize">
                                   <p className="mb-0 font-size-14">
                                      Filmes Assistidos
                                   </p>
                                </div>
                                <div className="icon iq-icon-box-top rounded-circle bg-info">
                                   <i className="las la-download"></i>
                                </div>
                             </div>
                             <div className="d-flex align-items-center justify-content-between mt-3">
                                <h4 className="mb-0">+0</h4>
                                <p className="mb-0 text-info"><span><i className="fa fa-caret-up mr-2"></i></span>0%</p>
                             </div>
                          </Card.Body>
                       </Card>
                    </Col>
                    
                 </Row>
                 <Card id="slider1">
                    <Card.Header className="d-flex justify-content-between align-items-center">
                           <div>
                              <h4 className="card-title m-0">TOP 10 da Semana </h4>
                           </div>
                           <div className="" id="swiper">
                              <div className="swiper-button swiper-button-prev">Voltar</div>
                              <div className="swiper-button swiper-button-next">Avançar</div>
                           </div>
                    </Card.Header>
                    <Card.Body>
                       <Swiper
                           navigation={{
                              prevEl: '.swiper-button-prev',
                              nextEl: '.swiper-button-next'
                           }}
                           breakpoints={{
                              320: {   slidesPerView: 1},                
                              550: { slidesPerView: 2 },
                              991: { slidesPerView: 3 },
                              1400: { slidesPerView: 4 },
                           }}
                           loop={true}
                           className="list-unstyled row top-rated-item mb-0 iq-rtl-direction"
                        >
                          {movies.map((movie, index) => {
                             return (
                              <SwiperSlide className="col-sm-6 col-lg-4 col-xl-3 iq-rated-box">
                                 <Card className="mb-0">
                                    <Card.Body className="p-0">
                                       <div className="iq-thumb">
                                          <Link to="#">
                                             <img src={`${url}/api/user/profile/${movie.desimage}`} className="img-fluid w-100 img-border-radius" alt=""/>
                                          </Link>
                                       </div>
                                       <div className="iq-feature-list">
                                          <h6 className="font-weight-600 mb-0">{movie.desname}</h6>
                                          <p className="mb-0 mt-2">{movie.desdescription.substring(0, 15) + "..."}</p>
                                          <div className="d-flex align-items-center my-2 iq-ltr-direction">
                                             <p className="mb-0 mr-2"><i className="lar la-eye mr-1"></i> 0</p>
                                             <p className="mb-0 "><i className="las la-download ml-2"></i> 0 </p>
                                          </div>
                                       </div>
                                    </Card.Body>
                                 </Card>
                              </SwiperSlide>
                               )
                          })}
                         
                          </Swiper>
                    </Card.Body>
                 </Card>
              </Col>
           </Row>
           <Row>
              <Col sm="12">

                    <Card>
                        <Card.Header className="d-flex justify-content-between no-bottom-border" >
                            <Card.Header.Title>
                                <h4 className="card-title">Vídeos Recentemente Adicionados</h4>
                            </Card.Header.Title>
                        </Card.Header>
                        <div className="datatable-input-holder">
                            <input type="text" className="form-control datatable-search" id="input-search-data" placeholder="Pesquisar" />
                        </div>
                        <Card.Body>
                        <div class="table-responsive p-0 pb-2">
                            <table id="table-filco" className="table align-items-center justify-content-center mb-0">
                                <thead>
                                    <tr>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Filme</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Mentores</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Descrição</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Data Registro</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody></tbody>
                            </table>
                        </div>
                        </Card.Body>
                    </Card>
              </Col>
           </Row>
        </Container>
        </>
    )
}

export default Dashbord;