import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
// import {Container,Col,Row }from 'react-bootstrap'
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {rtlModeAction, getRtlMode} from '../../../../store/mode/rtlmode'


const mapStateToProps = (state) => {
    return {
        rtlMode: getRtlMode(state)
    };
}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            rtlModeAction
        },
        dispatch
    )
})
    
const anoAtual = new Date().getFullYear();



const FooterStyle = (props) =>{

    return(
        <>
            <footer className="iq-footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6">
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item"><Link to="/privacy-policy">Política de Privacidade</Link></li>
                        </ul>
                        </div>
                        <div className="col-lg-6 text-right">
                         <Link to="#">© {anoAtual} AMERICAN DREAM CLASS. Todos os direitos reservados.</Link>
                        </div>
                    </div>
                </div>
            </footer>   
        </>
    )
}

export default  connect(mapStateToProps, mapDispatchToProps)(FooterStyle)