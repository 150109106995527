import React from 'react'
import { Container ,Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import user from '../../../../assets/images/user/user.jpg'

import Swal from 'sweetalert2'
import axios from 'axios'
import JsonWebToken from '../../../../router/auth/JsonWebToken';

import DreamClassPayments from '../../../../controller/DreamClassPayments'


let publicKey = "";


let privateKey = 'TEST-1276450813396396-060207-be6cf3f3a9f226a711361067e92ac30a-1135190200';


let _DreamClassPayment = new DreamClassPayments(publicKey, privateKey, process.env.REACT_APP_URL);




let _jwt = new JsonWebToken(localStorage.getItem("auth"));

const UserAccountSetting = () => {

      let isLogged = _jwt.validateToken();
      _jwt.retrieveAllData(isLogged).then(_data => {
            document.getElementById("desname").innerHTML  = _data.data[0].desname;
            document.getElementById("dtbirth").innerHTML  = _data.data[0].dtbirth;
            document.getElementById("desdescription").innerHTML  = _data.data[0].desdescription;
            document.getElementById("desemail").innerHTML  = _data.data[0].desemail;
            document.getElementById("profileImage").src  = process.env.REACT_APP_URL +  _data.data[0].desprofile;
            document.getElementById("despassword").innerHTML  = "*****";
            document.getElementById("deslanguage").innerHTML  = "PT-BR";


           if(_data.data[0].optsignature === 0){
                document.getElementById("next-cobranca").innerHTML  = "Nenhuma Cobrança Pendente";
                document.getElementById("botao-assinatura").innerHTML = "Adquirir Assinatura";
                document.getElementById("botao-assinatura").onclick = function(){
                   window.location.href = "/"
                }
           }else{
              //hide "botao-assinatura"
               document.getElementById("next-cobranca").innerHTML  = "Próxima cobrança em breve";
               document.getElementById("botao-assinatura").style.display = "Cancelar Assinatura";
               //remove "to" from "botao-assinatura"
               document.getElementById("botao-assinatura").href = "#";
               //add onClick event in "botao-assinatura"
               document.getElementById("botao-assinatura").onclick = function(){
                     //open swal with loading screen
                     Swal.fire({
                        title: 'Cancelando Assinatura...',
                        icon: 'warning',
                        html: 'Aguarde enquanto validamos os dados...',
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: false,
                        didOpen: () => {
                            Swal.showLoading()
                            //Axios Call
                            _DreamClassPayment.CloseSignature({
                                userID:  _data.data[0].iduser,
                            })
                            .then(function (data) {
                                Swal.fire({
                                    title: `Assinatura Cancelada!`,
                                    icon: 'info',
                                    html: `Para prosseguir clique em "Avançar"`,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                    showConfirmButton: true,
                                    showCloseButton: true,
                                    confirmButtonText: 'Avançar',
                                    closeButtonText: 'Fechar',
                                }).then((modal) => {
                                    if (modal.value) {
                                        //jsoSON Decode data
                                       //redirect to "botao-assinatura"
                                       document.getElementById("next-cobranca").innerHTML  = "Nenhuma cobrança encontrada";
                                       document.getElementById("botao-assinatura").innerHTML = "Adquirir Assinatura";
                                    }
                                })
                             })
                            .catch(error => {
                                Swal.fire({
                                    title: `Ocorreu um Erro (${error ? error.code : ''})`,
                                    icon: 'error',
                                    html: `Ocorreu um erro ao tentar iniciar a transação - ${error ?JSON.parse(error.response.data.data).message : ''}`,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                    showConfirmButton: false,
                                    showCloseButton: true,
                                    closeButtonText: 'Fechar',
                                    timer: 2000
                                })
                            })
            
                        },
                        allowOutsideClick: () => !Swal.isLoading()
                    }) 
               }
           }

      })

    return (
      <>
         <section className="m-profile setting-wrapper">        
            <Container>
               <h4 className="main-title mb-4">Configurações da Conta</h4>
                  <Row>
                     <Col lg="4" className="mb-3">
                        <div className="sign-user_card text-center">
                           <img src={user} id="profileImage" className="rounded-circle avatar-130 img-fluid d-block mx-auto mb-3" alt="user"/>
                           <h4 id="desname" className="mb-3">....</h4>
                           <p id="desdescription">[...]</p>
                           <Link to="/manage-profile" className="edit-icon text-primary">Alterar</Link>
                        </div>
                     </Col>
                     <Col lg="8">
                        <div className="sign-user_card">
                           <h5 className="mb-3 pb-3 a-border">Informações Pessoais</h5>
                           <Row className="row align-items-center justify-content-between mb-3">
                              <Col md="12">
                                 <span className="text-light font-size-13">Seu E-mail</span>
                                 <p id="desemail" className="mb-0"  >...</p>
                              </Col>   
                           </Row>
                           <Row className="align-items-center justify-content-between mb-3">
                              <Col md="8">
                                 <span className="text-light font-size-13">Sua Senha</span>
                                 <p id="despassword" className="mb-0">....</p>
                              </Col>
                              <Col md="4" className="text-md-right text-left">                      
                                 <Link to="#" className="text-primary">Alterar Senha</Link>
                              </Col>
                           </Row>
                           <Row className="align-items-center justify-content-between mb-3">
                              <Col md="12">
                                 <span className="text-light font-size-13">Seu Aniversário</span>
                                 <p id="dtbirth" className="mb-0">...</p>
                              </Col>
                           </Row>
                           <Row className="align-items-center justify-content-between">
                              <Col md="12">
                                 <span className="text-light font-size-13">Língua Padrão</span>
                                 <p id="deslanguage" className="mb-0">...</p>
                              </Col>
                           </Row>
                           <h5 className="mb-3 mt-4 pb-3 a-border">Informações de cobrança</h5>
                           <Row className="justify-content-between mb-3">
                              <Col md="12" className="r-mb-15">
                                 <p id="next-cobranca">Sua próxima cobrança será feita no dia 12/06/2022</p>
                                 <Link id="botao-assinatura"  to="#" className="btn btn-hover">Cancelar Assinatura</Link>
                              </Col>
                           </Row>
                           <h5 className="mb-3 pb-3 mt-4 a-border">Opções</h5>
                           <Row>
                              <div className="col-12 setting">
                                 <Link to="/auth/logout" className="text-body d-block mb-1">Desconectar-se</Link>
                                 <Link to="/" className="text-body d-block mb-1">Voltar ao início</Link>
                              </div>                            
                           </Row>
                        </div>
                     </Col>
                  </Row>
            </Container>
         </section>
      </>
   )
}

export default UserAccountSetting