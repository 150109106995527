import React,{useEffect, useState} from 'react'
import { Route, Redirect } from "react-router-dom";
import jwt from "jsonwebtoken";


import UserProfile from '../../views/backend/app/usermanagement/userprofile'

import JsonWebToken from "./JsonWebToken";

import FooterStyle from '../../components/partials/backend/footerstyle/footerstyle'
import HeaderStyle1 from '../../components/partials/backend/headerstyle/headerstyle1';

// receives component and any other props represented by ...rest
export default function ProtectedRoutes({ component: Component, target: TargetComponent, IsAuthRoute, ShouldHaveSignature, ...rest }) {
 
  const [token, setToken] = useState('');
  const [isDone, setIsDone] = useState(false);
  const _token = new JsonWebToken(localStorage.getItem("auth"));
        
  //use state 
  useEffect(() => {
    _token.retrieveAllData( _token.validateToken() ).then(data => {
      setToken(data)
      setIsDone(true)
    });

  }, []);
 
  return (
    // this route takes other route assigned to it from the App.js and return the same route if condition is met
    <Route
      {...rest}
      render={(props) => {
        let _token_ = token.data ? token.data[0].optsignature : false;
        if(isDone){
          let isValidated = _token.validateToken();
          /**
           * @descriptionF1 Se estiver tentando acessar uma rota de autenticação e o usuário for válido, redireciona para a página principal
           * @descriptionF2 Se for uma rota de autenticação e o usuário não for válido, carrega o componente atual.
          */
          if(IsAuthRoute){
            if(!isValidated){
              return (
                <>
                  <Component {...props} />
                  <FooterStyle />
                </>
              );
            }
            else{
              return (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              );
            }
          }
          /**
           * @descriptionF1 Se estiver tentando acessar uma página do sistema que precisa ter assinatura e o usuário for válido, então carrega o component
          */
          else if( !IsAuthRoute && ShouldHaveSignature && isValidated ){
        
            
            if(isValidated && _token_){
              //return component and footer style
              return (
                <>
                  <Component {...props} />
                  <FooterStyle />
                </>
              );
            }
            else if(isValidated && !_token_){
              //TargetComponent geralmente é o 'Pricing', a página de adquirir o plano de assinatura.
              // console.log({
              //   isValidated: isValidated,
              //   _token_: _token_
              // })
              return (
                <>
                  <TargetComponent {...props} />;
                  <FooterStyle />
                </>
              );
            }
          }
          else{
            /**
             * @descriptionF1 Este else é pra quando o usuário está tentando acessar uma página que precisa de login, mas NÃO de assinatura.
             * @descriptionF1 Se o usuário não for válido, leva pra página de login
            */
            if(isValidated === false){
              //return back
              return (
                <Redirect
                  to={{
                    pathname: "/auth/login",
                    state: {
                      // sets the location a user was about to assess before being redirected to login
                      from: props.location,
                    },
                  }}
                />
              );
            }
            /**
             @descriptionF1 Se o usuário for válido, carrega o componente atual
            */
            else{
              return (
                <>
                  <Component {...props} />
                  <FooterStyle />
                </>
              );
            }
          }
        }
        
      }}
    />
  );
}