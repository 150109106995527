import React from 'react';

// Partial
import HeaderStyle1 from '../../components/partials/dashboard/headerstyle/headerstyle1'
import SidebarStyle from '../../components/partials/dashboard/sidebarstyle/sidebarstyle'
import FooterStyle from '../../components/partials/dashboard/footerstyle/footerstyle'

import FunctionaryDashboard from '../../router/functionary-dashboard'


const BlankDashboardLayout = () => {


    return (
        <>
            <div className="wrapper">
                <div className="main">
                    <SidebarStyle />
                    <div className="content-page" id="content-page">
                        <FunctionaryDashboard />
                    </div>
                </div>


                <FooterStyle />
            </div>
        </>
    )
}


export default BlankDashboardLayout;