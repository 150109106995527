import React, { useEffect, useState } from "react";
import { Container, Col, Row, Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from "swiper";
import "swiper/swiper-bundle.css";

//images
import DownloadIcon from "../../../assets/images/DownloadIcon.png";
import trending from "../../../assets/images/trending/trending-label.png";
import movie1 from "../../../assets/images/movies/06.jpg";
import movie2 from "../../../assets/images/movies/07.jpg";
import movie3 from "../../../assets/images/movies/08.jpg";
import movie4 from "../../../assets/images/movies/09.jpg";
import movie5 from "../../../assets/images/movies/10.jpg";
import upcoming1 from "../../../assets/images/upcoming/01.jpg";
import upcoming2 from "../../../assets/images/upcoming/02.jpg";
import upcoming3 from "../../../assets/images/upcoming/03.jpg";
import upcoming4 from "../../../assets/images/upcoming/04.jpg";
import upcoming5 from "../../../assets/images/upcoming/05.jpg";
import MovieTable from "../../../database/table/series";
import ListRelatedMovies from "../../../database/table/related";
import logo from "../../../assets/images/logo.png";
import video from "../../../assets/video/sample-video.mp4";
import Attachments from "../../../database/table/attachments";



// install Swiper modules
SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);
const TableMovie = new MovieTable();
const TableRelated_ = new ListRelatedMovies();
const TableAttachments = new Attachments();
const url = process.env.REACT_APP_URL;



const AddMovie = ({ match }) => {
	const [movies, setMovies] = useState([]);
	const [series, setSeries] = useState([]);
	const [idmovie, setid] = useState([]);
	const [materials, setMaterials] = useState([]);
	const [materialHeight, setMaterialHeight] = useState(0);
	// const materialHeight = materials.length >= 3 ? 530 : 250;

	useEffect(() => {
		TableMovie.get_detailed().then((res) => {
			console.log(res)
			setMovies(res);
		});
	
		TableAttachments.get(match.params.id).then((res) => {
			
			console.log(res)
			setMaterialHeight( res.length >= 3 ? 530 : 250)
			setMaterials(res);
		});



		TableRelated_.get(match.params.id).then((res) => {
			if (res.length < 0) {
				window.location.href = `/`;
			}
			//get first item of array
			const firstItem = res[0];
			//get id of first item
			if (firstItem) {
				setid(firstItem.id);
			}
			//order res by id
			const ordered = res.sort((a, b) => a.id - b.id);
			setSeries(ordered);
		});
	}, []);

	return (
		<>
			{movies
				.filter((movie) => movie.id == match.params.id)
				.map((movie) => (
					<section
						style={{
							backgroundImage: `url(${url}/api/user/profile/${movie.desbanner})`,
							height: `100vh`,
							backgroundSize: `contain`,
						}}>
						<Container fluid className="h-100 d-flex align-items-end">
							<Col lg="12" className="r-mb-23">
								<div className="text-left">
									<Link to="#">
										<h2
											className="main-title"
											data-iq-gsap="onStart"
											data-iq-position-x="-200">
											{movie.desname}
										</h2>
									</Link>
									<div
										className="d-flex align-items-center r-mb-23 mb-5 pb-5 btn-wrapper"
										data-iq-gsap="onStart"
										data-iq-position-y="80"
										data-iq-delay="0.8">
										<Link
											to={`/show-details/${movie.id}`}
											className="mt-3 font-weight-bold btn btn-hover iq-button"
										>
											Assista agora
										</Link>
										{/* <Link to="/show-details" className="btn btn-link">More details</Link> */}
									</div>
								</div>
							</Col>
						</Container>
					</section>
				))}
			<div className="main-content movi">
				<section
					id="iq-favorites"
					className="s-margin iq-rtl-direction">
					<Container fluid>

						{movies
							.filter((movie) => movie.id == match.params.id)
							.map((movie) => (
								<div class="series-info-modal">
									<h5>Categoria: &nbsp;<strong>{movie.descategory}</strong></h5>
									<h5>Quantidade de episódios: &nbsp;<strong>{movie.totalEpisodes}</strong></h5>
									<h5>Duração:&nbsp; <strong>{movie.totalDurationFormated}</strong></h5>
								</div>
							))}
							<Col sm="12" className="pt-5 overflow-hidden">
								<h4
									className="main-title"
									data-iq-gsap="onStart"
									data-iq-position-x="-200">
									Sobre:
								</h4>
								{movies.map((movie, index) => {
									if (
										match.params.id == movie.id
									) {
										return (
											<div className="d-flex flex-wrap align-items-center mb-3">
												<p
													data-iq-gsap="onStart"
													data-iq-position-y="80"
													data-iq-delay="0.8">
													{
														movie.desdescription
													}
												</p>
											</div>
										);
									}
								})}
								<Row>
									<Col lg="9">
										{series.map((movie, index) => {
											return (
												<Row className="episodes-wrapper">
													<Link
														to={`/show-details/${movie.id}`}>
														
														<img src={`${url}/api/user/profile/${movie.desimage}`}></img>
													</Link>
													<div className="blue-box">
														<h4 className="mb-2">
															{movie.desname}
														</h4>
														<p>
															{movie.desdescription}
														</p>
													</div>
												</Row>
											);
										})}
									</Col>
									<Col>
										{series.length > 0 ? (

											<div className="grey-box" 
												/* change value of --iq-white-color to change the color of the box */
												style={{ '--iq-material-height': materialHeight  + 'px' }}
											>
												{materials.map((material, index) => {
													return (
														<div class="row grey-item mb-3">
															<div class="col-3">
																<a href={`${url}/api/view/attachment/download/${material.desfile}`}>
																<img src={DownloadIcon}></img></a>
															</div>
															<div class="col-9">
																<strong class="text-md">Material de Apoio:</strong>
																<br/>
																<a href={`${url}/api/view/attachment/download/${material.desfile}`}>{material.desname}</a>
															</div>
														</div>	
													)
												}
												)}
											</div>
										)  
										: null}
									</Col>
								</Row>
								<div>
									<h4 className="main-title pb-3">
										Cursos Relacionados
									</h4>
								</div>
								<div id="related-content">
									<div
										className="swiper-button swiper-button-prev">
										<i id="prev1" className="fa fa-chevron-left"></i>
									</div>
									<div
										className="swiper-button swiper-button-next" >
										<i id="next1" className="fa fa-chevron-right"></i>
									</div>
									<Swiper
										slidesPerView={5}
										spaceBetween={20}
										navigation={{
											prevEl: "#prev1",
											nextEl: "#next1",
										}}
										breakpoints={{
											320: { slidesPerView: 1.5 },
											550: { slidesPerView: 2.5 },
											991: { slidesPerView: 3.5 },
											1400: { slidesPerView: 4.5 },
										}}
										loop={true}
										as="ul"
										className="list-inline favorites-slider row p-0 m-0 iq-rtl-direction">
										{movies
											.sort(() => Math.random() - 0.5)
											.slice(0, 4)
											.map((movie, index) => {
												return (
													<SwiperSlide className="d-flex flex-column align-items-center" as="li">
														<Link
															to={`/movie-details/${movie.id}`}>
															<div className="block-images position-relative">

																<div className="img-box">

																	<img
																		src={`${url}/api/user/profile/${movie.desimage}`}
																		className="img-fluid"
																		alt=""
																	/>

																</div>

															</div>
														</Link>
														<div class="divider"></div>
													</SwiperSlide>
												);
											}
											)}
									</Swiper>
								</div>
							</Col>
					</Container>
				</section>
			</div>
		</>
	);
};
export default AddMovie;
