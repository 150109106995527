import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Card from '../../../components/Card'
import Swal from 'sweetalert2'
//img
import user01 from '../../../assets/images/user/01.jpg'
import user05 from '../../../assets/images/user/05.jpg'
import user03 from '../../../assets/images/user/03.jpg'
import user08 from '../../../assets/images/user/08.jpg'
import user06 from '../../../assets/images/user/06.jpg'
import user10 from '../../../assets/images/user/10.jpg'

import MovieTable from '../../../database/table/movie'

import axios from 'axios'

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

const TableMovie = new MovieTable()

function Redirect(Serie){
    window.location.href = Serie
}

function RemoveEpisode(Episode){

    Swal.fire({
        title: 'Você tem certeza de que deseja remover este episódio?',
        text: "Você não poderá reverter isso! Este episódio será completamente removido do servidor!",
        focusConfirm: false,
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Remover Epispódio!',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        showCancelButton: true,
        cancelButtonText: '<i class="fa fa-thumbs-down"></i> Cancelar',
        cancelButtonColor: '#d33',
        }).then((result) => {

            if (result.value) {
                console.log(result)
                const data = new FormData();
                data.append("id", Episode.id);
                data.append("desimage", Episode.desimage);
                data.append("desvideo", Episode.desvideo);
                Swal.fire({
                    title: 'Autenticando...',
                    icon: 'warning',
                    html: 'Aguarde enquanto validamos os dados...',
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: false,
                    didOpen: () => {
                        Swal.showLoading()
                        //Axios Call
                        axios({
                            method: 'post',
                            url: process.env.REACT_APP_URL + '/api/movie/delete',
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Accept': 'application/json'
                            },
                            data : data
                        })
                        .then(function (response) {
                                if(response.data){
                                    Swal.close()
                                    Swal.fire({
                                        title: 'Atualizado com Sucesso!',
                                        text: "Dados atualizados com sucesso!",
                                        icon: 'success',
                                        confirmButtonText: 'OK'
                                    }).then((result) => {
                                        if (result.value) {
                                            window.location.reload()
                                        }
                                    })

                                }
                        })
                        .catch(error => {
                                //Close Loading Modal
                                Swal.close();
                                //Show Error
                                Swal.fire({
                                    title: 'Ocorreu um erro!',
                                    text: error.response,
                                    icon: 'error',
                                    confirmButtonText: 'OK'
                                })
                        })

                    },
                    allowOutsideClick: () => !Swal.isLoading()
                })
            }
    })
}

const User = () => {

    const [users, setUsers] = useState([])

    useEffect(() => {

        TableMovie.get().then(res => {



            //foreach res.desactors, json.parse and convert to string
            res.map(item => {
                //json decode item.desactors and convert desactor.label to string separated with ","
                item.desactors = JSON.parse(item.desactors).map(desactor => desactor.label).join(', ')
            })

            setUsers(res)
        })

    }, []);

    window.RemoveEpisode = RemoveEpisode
    window.Redirect = Redirect  
     //if table already loaded then destroy it
     if ($.fn.DataTable.isDataTable("#table-movie")) {
         $("#table-movie").DataTable().destroy();
     }

     if (users.length > 0) {
         console.log({
             data: users
         })
         const table = $("#table-movie").DataTable({
             data: users,
             columns: [
                 { data: "id" },
                 { data: "serie_name" },
                 { data: "desname" },
                 { data: "desdescription" },
                 { data: "desactors" },
                 { data: "desseason" },
                 { data: "desepisode" },
                 { data: "dtregister" },
                 { data: "dtregister" },
             ],
             //style last column
             columnDefs: [
                {
                    targets: 2,
                    render: function (data, type, row) {
                       //se a descricao for maior que 50 caracteres, entao corta e adiciona ...
                       if (data.length > 20) {
                           return data.substr(0, 20) + "...";
                       }
                       return data;
                    }
                },
                {
                     targets: 3,
                     render: function (data, type, row) {
                        //se a descricao for maior que 50 caracteres, entao corta e adiciona ...
                        if (data.length > 20) {
                            return data.substr(0, 20) + "...";
                        }
                        return data;
                     }
                 },
                 {
                    targets: 8,
                    render: function (data, type, row) {
                        let frow = JSON.stringify(row).replace(/"/g, "'");
                        //button with row object onclick
                        return `<button class="btn-sm btn-primary"  onclick="Redirect('/manager/edit-movie/${row.id}')"><i class="lar ri-pencil-line"></i> </button>
                                <button class="btn-sm btn-danger"   onclick="RemoveEpisode(${frow})"><i class="ri-delete-bin-2-line"></i></button>`;
                    }
                },
             ],
             //hide "shoe entries button"
             lengthChange: false,
             //change position of "search" input
             dom: 'lrtip',
         });
         // #column3_search is a <input type="text"> element
         $('#input-search-data').on( 'keyup', function () {
             //saerch from table by name in all columns
             table.search( this.value ).draw();
         } );
 }
    



    return (
        <>
            <Container fluid>
                    <Card>
                        <Card.Header className="d-flex justify-content-between no-bottom-border" >
                            <Card.Header.Title>
                                <h4 className="card-title">Vídeos Recentemente Adicionados</h4>
                            </Card.Header.Title>
                        </Card.Header>
                        <div className="datatable-input-holder">
                            <input type="text" className="form-control datatable-search" id="input-search-data" placeholder="Pesquisar" />
                        </div>
                        <Card.Body>
                        <div class="table-responsive p-0 pb-2">
                            <table id="table-movie" className="table align-items-center justify-content-center mb-0">
                                <thead>
                                    <tr>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">ID</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Curso</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Nome</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Descrição</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Mentores</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Temporada</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Aula</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Data</th>
                                        <th></th>
                                   
                                    </tr>
                                </thead>
                                <tbody></tbody>
                            </table>
                        </div>
                        </Card.Body>
                    </Card>
            </Container>

        </>
    )
}
export default User;