import React,{useState, useEffect} from 'react'
import {Col,Row,Container} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import footer1 from '../../../../assets/images/footer/01.jpg'
import footer2 from '../../../../assets/images/footer/02.jpg'
import logo from "../../../../assets/images/logo.png";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

const mapStateToProps = (state) => {
    return {
        rtlMode: 'rtl'
    };
}
let rtlModeAction = 'rtl'
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            rtlModeAction
        },
        dispatch
    )
})



const FooterStyle = (props) =>{
    //log isdone from local storage
    console.log(localStorage.getItem("isDone"));
    return(
        <>
            <footer id="contact" className="footer-one">
                <div className="footer-top">
                    <Container fluid>
                        <Row className="footer-standard">
                            <Col className="first-column" lg="7">
										<img
											className="img-fluid logo"
											src={logo}
											alt="streamit"
										/>
                                <div className="widget text-left">
                                    <div className="textwidget">
                                        <p><small>© 2022 AMERICAN DREAM CLASS. Todos os direitos reservados. Todo o conteúdo nesta plataforma é marca registrada e todas as imagens e conteúdos relacionados são propriedade da ???. A reprodução e cópia deste é estritamente proibida.</small></p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="2" md="6" className="second-column mt-4 mt-lg-0">
                                <Row>
                                <h6 className="footer-link-title mr-3 font-weight-light">
                                    Redes Sociais
                                </h6>
                                <ul className="d-flex info-share">
                                    <li><Link target="_blank" to="#"><i className="fa fa-facebook"></i></Link></li>
                                    <li><Link target="_blank" to="#"><i className="fa fa-twitter"></i></Link></li>
                                    <li><Link target="_blank" to="#"><i className="fa fa-google-plus"></i></Link></li>
                                    <li><Link target="_blank" to="#"><i className="fa fa-github"></i></Link></li>
                                </ul>
                                </Row>
                                    <Row className="">
                                <h6 className="footer-link-title mr-3 font-weight-light">
                                    Termos de Uso
                                </h6>
                                <h6 className="footer-link-title mr-3 font-weight-light">
                                    Política de Privacidade
                                </h6>
                                    </Row>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </footer>
        </>
    )
}

export default  connect(mapStateToProps, mapDispatchToProps)(FooterStyle)
