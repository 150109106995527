import user from "./json/user.json";
import functionary from "./json/functionary.json";
export default class Routes{



    /**
     * @param {*} UserLevel
     * @return {object}
     */
    getRoutes(UserLevel) {
        let routes = [];


        functionary.forEach(element => {
            if(element.auth <= UserLevel){
                routes.push(element);
            }
        });


        user.forEach(element => {
            if(element.auth <= UserLevel){
                routes.push(element);
            }
        });

        return routes;
    }



}