import { Switch, Route, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Pricing from "../views/backend/main/pricing";

//App
import UserProfile from "../views/backend/app/usermanagement/userprofile";
import UserAccountSettingList from "../views/backend/app/usermanagement/useraccountsetting";

//Extrapages
import FAQ from "../views/backend/pages/faq";
import TermsOfUse from "../views/backend/pages/extrapages/termsOfUse";
import PrivacyPolicy from "../views/backend/pages/extrapages/privacyPolicy";
import AboutUs from "../views/backend/pages/about-us";
import Contact from "../views/backend/pages/contact";
import Courses from "../views/backend/pages/courses";
import Search from  "../views/backend/pages/search";
//Category
import CategoryList from "../views/backend/category/category-list";

//Movie
import AddMovie from "../views/backend/movie/add-movie";
import MovieList from "../views/backend/movie/movie-list";

//Show
import ShowList from "../views/backend/show/show-list";

// Home
import Homepage from "../views/backend/home/home";

import ProtectedRoutes from "./auth/ProtectedRoutes";

const Layout1Route = () => {

    let location = useLocation();

    return (
        <TransitionGroup>
            <CSSTransition
            // key={location.key}
            classNames="fade"
            timeout={300}
            >
                <Switch location={location}>
                    {/* App */}
                    <ProtectedRoutes path="/manage-profile" component={UserProfile} />
                    <ProtectedRoutes path="/setting"  component={UserAccountSettingList} />
                    
                    {/* Extrapages */}
                    <ProtectedRoutes path="/privacy-policy"     component={PrivacyPolicy} />
                    <ProtectedRoutes path="/about-us" component={AboutUs}/>
                    <ProtectedRoutes path="/contact"  component={Contact}/>
                   
                    {/* Category & Search*/}
                    <ProtectedRoutes target={Pricing}  ShouldHaveSignature path="/courses/:category?" component={Courses}/>
                    <ProtectedRoutes target={Pricing}  ShouldHaveSignature path="/find/:s"  component={Search}/>
                    {/* Movie */}
                    <ProtectedRoutes target={Pricing}  ShouldHaveSignature path="/movie-details/:id" component={AddMovie}/>
                    <ProtectedRoutes target={Pricing}  ShouldHaveSignature path="/movie-category" component={MovieList}/>
                    {/* Show */}
                    <ProtectedRoutes target={Pricing}  ShouldHaveSignature path="/show-details/:id" component={ShowList}/>
                    {/* Homepage */}
                    <ProtectedRoutes target={Pricing}  ShouldHaveSignature path="/:id?" exact component={Homepage}/>
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    )
}

export default Layout1Route
 