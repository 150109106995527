import React,{useEffect, useState} from 'react'

import { Link} from 'react-router-dom'
import {Container,Row,Col,OverlayTrigger,Tooltip, Button} from 'react-bootstrap'
import Card  from '../../../components/Card'

import SignatureTable_ from '../../../database/table/signature'

import Swal from 'sweetalert2'
import axios from 'axios'

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

const AddNewDirector =() =>{
  
    Swal.fire({
        title: 'Novo Diretor',
        html: '<input id="swal-input1" placeholder="Nome do Diretor" class="swal2-input">' + 
        '<textarea id="swal-input2" placeholder="Descrição do Diretor"  class="swal2-input"></textarea>',
        focusConfirm: false,
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Cadastrar Diretor',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        showCancelButton: true,
        cancelButtonText: '<i class="fa fa-thumbs-down"></i> Cancelar',
        cancelButtonColor: '#d33',
        preConfirm: () => {
          return [
            document.getElementById('swal-input1').value,
            document.getElementById('swal-input2').value
          ]
        },
        }).then((result) => {
            //decode result
            const [name,description] = result.value
            if(name && description){
                  //swal with loading screen

                    const data = new FormData();
                    data.append("desname", name);
                    data.append("desdescription", description);
                    

                    Swal.fire({
                        title: 'Autenticando...',
                        icon: 'warning',
                        html: 'Aguarde enquanto validamos os dados...',
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: false,
                        didOpen: () => {
                            Swal.showLoading()
                            //Axios Call
                            axios({
                                method: 'post',
                                url: process.env.REACT_APP_URL + '/api/director/register',
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                    'Accept': 'application/json'
                                },
                                data : data
                            })
                            .then(function (response) {
                                    if(response.data){
                                        Swal.close()
                                        Swal.fire({
                                            title: 'Atualizado com Sucesso!',
                                            text: "Dados atualizados com sucesso!",
                                            icon: 'success',
                                            confirmButtonText: 'OK'
                                        }).then((result) => {
                                            if (result.value) {
                                                window.location.reload()
                                            }
                                        })

                                    }
                            })
                            .catch(error => {
                                    //Close Loading Modal
                                    Swal.close();
                                    //Show Error
                                    Swal.fire({
                                        title: 'Ocorreu um erro!',
                                        text: error.response,
                                        icon: 'error',
                                        confirmButtonText: 'OK'
                                    })
                            })

                        },
                        allowOutsideClick: () => !Swal.isLoading()
                    })
            }else{
                Swal.fire(
                    'Categoria Não Criada!',
                    'Por favor, preencha todos os campos!',
                    'error'
                )
            }
    })
}



const SignatureTable = new SignatureTable_()


const SignatureList = () => { 

    const [signature,setSignature] = useState([])

    useEffect( () => {
        SignatureTable.get().then(res => {
        setSignature(res)
      })
    }, []);
     
      if ($.fn.DataTable.isDataTable("#table")) {
         $("#table").DataTable().destroy();
     }
        if ($.fn.DataTable.isDataTable("#table-list")) {
            $("#table-list").DataTable().destroy();
        }
 
     if (signature.length > 0) {
         console.log({
             data: signature
         })
       
         const table = $("#table-list").DataTable({
             data: signature,
             columns: [
                 { data: "id" },
                 { data: "desname" },
                 { data: "nomepessoa" },
                 { data: "desemail" },
                 { data: "desidpayment" },
                 { data: "id" },
                 { data: "id" },
                 { data: "id" },
             ],
            columnDefs: [   
                {
                    targets: 5,
                    render: function (data, type, row) {
                        return JSON.parse(row.desrecurring).transaction_amount
                    }
                },
                {
                    targets: 6,
                    render: function (data, type, row) {
                        return row.dtregister ? row.dtregister : "Não Encontrado"
                    }
                },
                {
                    targets: 7,
                    render: function (data, type, row) {
                        return row.desstatus == "authorized" ? "<span class='text-success'>Paga/Ativa</span>" : "<span class='text-warning'>Pendente</span>"
                    }
                },
            ], 
            //hide "shoe entries button"
            lengthChange: false,
            //change position of "search" input
            dom: 'lrtip',
         });
         // #column3_search is a <input type="text"> element
         $('#input-search-data').on( 'keyup', function () {
             //saerch from table by name in all columns
             table.search( this.value ).draw();
         } );

    }
    

    return (
            <> 
                <Container fluid>
                    <Card>
                        <Card.Header className="d-flex justify-content-between no-bottom-border" >
                            <Card.Header.Title>
                                <h4 className="card-title">Lista de Assinaturas</h4>
                            </Card.Header.Title>
                        </Card.Header>
                        <div className="datatable-input-holder">
                            <input type="text" className="form-control datatable-search" id="input-search-data" placeholder="Pesquisar" />
                        </div>
                        <Card.Body>
                        <div class="table-responsive p-0 pb-2 " > 
                            <table id="table-list" className="table align-items-center justify-content-center mb-0">
                                <thead>
                                    <tr>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">ID</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Título</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Cliente</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">E-mail</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">ID</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Valor</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Registro</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Status</th>
                                    </tr>
                                </thead>
                                <tbody></tbody>
                            </table>
                        </div>
                        </Card.Body>
                    </Card>
                </Container>
                 
            </>
    )
}
export default SignatureList;