import MercadoPago from 'mercadopago';
import axios from 'axios';

/**
     * @author Vitor Hugo
     * @description - This class will use to do everything related with MercadoPago {@class MercadoPago}
     * @class MercadoPago
     * @constructor
     * @param {string} config.publicKey
*/
export default class DreamClassPayments {
 
                
     /**
      * @author Vitor Hugo
      * @name Constructor, define routes and start class
      * @param {string} rootURL
      * @param {string} publicKey
      * @param {string} privateKey
      */
     constructor(publicKey = "", privateKey = "", rootURL = "",){
         this.root = rootURL;
         this.publicKey = publicKey;
         this.privateKey = privateKey;
     }



     

     /**
           * @author Vitor Hugo
           * @name GetPaymentMethods, this function will get all payment methods
           * @param {Object} SignatureUserData, this object will be used to create the signature
           * @returns {bool}
      */
      async ValidateNewSignature(SignatureUserData = {}){
          return new Promise((resolve, reject) => {
               //Assign the Signature Object
               let SignatureObject = {
                    preapproval_id: SignatureUserData.preapproval_id ,
               } 
               axios.post(`${this.root}/api/payment/validate`, SignatureObject)
               .then(function (response) {
                    resolve(JSON.stringify(response.data));
               })
               .catch(function (error) {
                    reject(error);
               })
          })
     }







     /**
           * @author Vitor Hugo
           * @name GetPaymentMethods, this function will get all payment methods
           * @param {Object} SignatureUserData, this object will be used to create the signature
           * @returns {bool}
      */
     async CreateNewSignature(SignatureUserData = {}){
          return new Promise((resolve, reject) => {
               //Assign the Signature Object
               let SignatureObject = {
                    userID: SignatureUserData.userID || null,
               } 
               //remove keys with null values
               Object.keys(SignatureObject).forEach(function(key) {
                    if (SignatureObject[key] === null) {
                         delete SignatureObject[key];
                    }
               });
         
               axios.post(`${this.root}/api/payment/register`, SignatureObject)
               .then(function (response) {
                    resolve(JSON.stringify(response.data));
               })
               .catch(function (error) {
                    reject(error);
               })
          })
     }


     /**
           * @author Vitor Hugo
           * @name GetPaymentMethods, this function will get all payment methods
           * @param {Object} SignatureUserData, this object will be used to create the signature
           * @returns {bool}
      */
      async CloseSignature(SignatureUserData = {}){
          return new Promise((resolve, reject) => {
               //Assign the Signature Object
               let SignatureObject = {
                    userID: SignatureUserData.userID ,
               } 
          
         
               axios.post(`${this.root}/api/payment/close`, SignatureObject)
               .then(function (response) {
                    resolve(JSON.stringify(response.data));
               })
               .catch(function (error) {
                    reject(error);
               })
          })
     }

     




    /**
      * @author Vitor Hugo
      * @name GetPaymentMethods, this function will get all payment methods
      * @param {Object} FormCard
      * @returns {bool}
    */
     async GetPaymentMethods(FormCard = null){
          return new Promise((resolve, reject) => {
               axios.get(`https://api.mercadopago.com/v1/payment_methods`, {
                    headers: {
                         'Content-Type': 'application/json',
                         'Accept': 'application/json',
                         'Authorization': `Bearer ${this.publicKey}` 
                    }
               })
               .then(function (response) {
                    resolve(response.data);
               })
               .catch(function (error) {
                    reject(error.response.data);
               })
          })
     }
     


     
}
     






 