import React,{useEffect, useState} from 'react'

import {Container,Row,Col,Form,Button} from 'react-bootstrap'
import Card  from '../../../components/Card'
import {useHistory} from 'react-router-dom'

import Swal from 'sweetalert2'
import axios from 'axios'
import JsonWebToken from '../../../router/auth/JsonWebToken'
import CategoryTable from '../../../database/table/category'
import ActorsTable from '../../../database/table/actors'
import SeriesTable from '../../../database/table/series'

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
// import { colourOptions } from './docs/data';

const animatedComponents = makeAnimated();

const CategoryTable_ = new CategoryTable()
const ActorsTable_ = new ActorsTable()
const SeriesTable_ = new SeriesTable()
const AddMovie = ({match}) => { 

  
    const [categories,setCategories] = useState([])
    const [selectedOption, setSelectedOption] = useState(null);
    const [currentSerie, setSeries] = useState([]);
    
    const TryRegisterMovie = (e) => {

        e.preventDefault();
   
        let _jwt = new JsonWebToken(localStorage.getItem("auth"));

        const data = new FormData();
        data.append('destitle',  e.target.destitle.value);
        data.append('desdescription', e.target.desdescription.value);
        data.append('desactors', JSON.stringify(selectedOption));
        data.append('desduration', e.target.desduration.value);
        data.append('desseason', e.target.desseason.value);
        data.append('desepisode', e.target.desepisode.value);

        data.append('id', _jwt.getId());
        data.append('idserie', currentSerie.id)
        data.append('seriefolder', currentSerie.desfolder);

        data.append('files', e.target.desimage.files[0]);
        data.append('files', e.target.desvideo.files[0]);
        //swal with loading screen
        console.log(data)
        Swal.fire({
                title: 'Cadastrando...',
                icon: 'warning',
                html: 'Aguarde enquanto validamos os dados...',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                    //Axios Call
                    axios({
                        method: 'post',
                        url: process.env.REACT_APP_URL + '/api/movie/register',
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Accept': 'application/json'
                        },
                        data : data
                    })
                    .then(function (response) {
                            if(response){
                                Swal.fire({
                                    title: 'Sucesso!',
                                    icon: 'success',
                                    html: 'Filme cadastrado com sucesso!',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                    showConfirmButton: false,
                                    timer: 2000,
                                }).then((timer) => {
                                    //redirect to manager/movie-list
                                    window.location.href = "/manager/movie-list"
                                }
                            )}
                    })
                    .catch(function ({response}) {
                        Swal.fire({
                            title: 'Erro!',
                            icon: 'error',
                            html: response.data.message ,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            showConfirmButton: false,
                            timer: 2000
                        })
                    });
                }
            })
        
    }

   //when a file is uploaded in #file input run this function
   const handleFileUpload = (e) => {
        //get file
        //when the file is uploaded change #profilepic img src to the file
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onloadend = () => {
            console.log('fim');
        }
        if (file) {
              document.getElementById('gallery2').innerHTML = file.name;
        }
        // e.target.value = "";
    }

    //when a file is uploaded in #file input run this function
    const handleVideoUpload = (e) => {
        //get file
        //when the file is uploaded change #profilepic img src to the file
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onloadend = () => {
            console.log('fim');
        }

        if (file) {
            document.getElementById('gallery1').innerHTML = file.name;
        }

        var video = document.createElement("video");
        video.preload = "metadata";

        video.onloadedmetadata = function () {
            window.URL.revokeObjectURL(video.src);

            var duration = video.duration;
            var minutes = parseInt(duration / 60, 10);
            var seconds = parseInt(duration % 60);
            var result = (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);
            document.getElementById('desduration').value = result;
        };

        video.src = URL.createObjectURL(file);
        // e.target.value = "";
    }

    let history =useHistory()

    const [actors, setActors] = useState([]);
 

    useEffect( () =>{

        CategoryTable_.get().then(res => {
            setCategories(res.filter(category => category.isactive === 1))
        })

        ActorsTable_.get().then(res => {
            //convert res to `{value: 'id', label: 'name'}`
            let actors = res.filter(actor => actor.isactive === 1) 
            let _actors = actors.map(actor => {
                return {value: actor.id, label: actor.desname}
            })
            setActors(_actors)
        })

    

        SeriesTable_.get(match.params.id).then(res => {
            //convert res to `{value: 'id', label: 'name'}`
          
            if(res.id == null){
              window.location.href = "/manager/show-list"
            }

            setSeries(res)
        })


    }, []);

   
        

     
        return (  
            
                <> 
                <Container fluid>
                    <Row>
                        <Col sm="12">
                            <Card>

                                <Card.Header className="d-flex justify-content-between">
                                    <Card.Header.Title>
                                        <h4 className="card-title text-capitalize">Nova Aula Para "{currentSerie.desname}"</h4>
                                    </Card.Header.Title>
                                </Card.Header>


                                <Card.Body>
                                      
                                    <Form onSubmit={TryRegisterMovie}>
                                        <Row>
                                            <Col lg="12">
                                                <Row>
                                                    <Form.Group className="col-12">
                                                        <Form.Control name="destitle" id="destitle"   type="text" placeholder="Título" required/>
                                                    </Form.Group>
                                                    

                                                    <div className="col-12 form_gallery form-group">
                                                        <label id="gallery2" htmlFor="desimage">Imagem de Capa</label>
                                                        <input id="desimage" onChange={handleFileUpload} name="desimage"  className="form_gallery-upload" type="file" accept=".png, .jpg, .jpeg"/>
                                                    </div>
                                                    <div className="col-12 form_gallery form-group">
                                                        <label id="gallery1" htmlFor="desvideo">Vídeo</label>
                                                        <input  id="desvideo" onChange={handleVideoUpload}  name="desvideo" className="form_gallery-upload"
                                                        type="file" accept="video/mp4,video/x-m4v,video/*"/>
                                                    </div>

                                                    {/* 
                                                    <Form.Group className="col-md-6">
                                                        <select name="descategory" id="descategory" className="form-control"  required>
                                                            <option selected disabled>Categoria do Episódio</option>
                                                            {categories.map((category) => (
                                                               <option key={category.id} value={category.id}>{category.desname}</option>
                                                            ))}
                                                        </select>
                                                    </Form.Group> */}

                                                    <Form.Group className="col-md-12">
                                                        <Select
                                                            placeholder="Mentores"
                                                            closeMenuOnSelect={false}
                                                            id="desactors"
                                                            name="desactors"
                                                            value={selectedOption}
                                                            onChange={setSelectedOption}
                                                            components={animatedComponents}
                                                            isMulti
                                                            options={actors}
                                                            />
                                                    </Form.Group>

                                                               

                                                    <Form.Group className="col-12">
                                                        <Form.Control name="desdescription" id="desdescription" as="textarea" rows="5" placeholder="Descrição [...]" required></Form.Control>
                                                    </Form.Group>
                                                </Row>
                                            </Col>

                                 
                                            <Form.Group className="col-12">
                                                        <Form.Control name="desseason" id="desseason"   type="number" placeholder="Temporada" required/>
                                            </Form.Group>
                                                    
                                            <Form.Group className="col-12">
                                                        <Form.Control name="desepisode" id="desepisode"   type="number" placeholder="Número da aula" required/>
                                            </Form.Group>
                                                    

                                                                


                                            <Col sm="12" className="form-group">
                                                <Form.Control id="desduration" hidden name="desduration" type="text" placeholder="Duração do Vídeo" required/>
                                            </Col>
                                        </Row>
                                        <Row>




                                            <Form.Group className="col-12">
                                                <Button className='btn-block text-capitalize' type="submit" variant="primary">Registrar Nova Aula para "{currentSerie.desname}"</Button>{' '}
                                            </Form.Group>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }

export default AddMovie;