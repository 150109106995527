import React,{useState,useEffect} from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import { useHistory ,Link} from 'react-router-dom'

// rtl 
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {rtlModeAction, getRtlMode} from '../../../../store/mode/rtlmode'
//Global Libs
import Swal from 'sweetalert2'
import axios from 'axios'
import logo from "../../../../assets/images/logo.png";

const RecoverPswd = ({match}) => {

    let history = useHistory()
    const [show, setShow] = useState(false);
    

        const TryToSaveBasicData = (e) => {
            e.preventDefault();
         
            let data = new FormData();
            data.append("token", match.params.id);
            data.append("despassword", e.target.newpassword.value);

              //swal with loading screen
                Swal.fire({
                    title: 'Autenticando...',
                    icon: 'warning',
                    html: 'Aguarde enquanto validamos os dados...',
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: false,
                    didOpen: () => {
                        Swal.showLoading()
                        //Axios Call
                        axios({
                            method: 'post',
                            url: process.env.REACT_APP_URL + '/api/auth/recovery/change',
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Accept': 'application/json'
                            },
                            data : data
                        })
                        .then(function (response) {
                                    Swal.close()
                                    Swal.fire({
                                        title: 'Atualizado com Sucesso!',
                                        text: "Senha atualizada com sucesso!",
                                        icon: 'success',
                                        confirmButtonText: 'OK'
                                    }).then((result) => {
                                        if (result.value) {
                                            window.location.href = "/setting";
                                        }
                                    })
                        })
                        .catch(error => {
                                //Close Loading Modal
                                Swal.close();
                                //Show Error
                                Swal.fire({
                                    title: 'Ocorreu um erro!',
                                    text: error.response,
                                    icon: 'error',
                                    confirmButtonText: 'OK'
                                })
                        })

                    },
                    allowOutsideClick: () => !Swal.isLoading()
                    })
        }
        
    return (
        <>
            <section className="sign-in-page">
            <Container>
                    <Row className="justify-content-center align-items-center height-self-center">
                        <Col lg="8" md="12" className="align-self-center">
                            <div className="sign-user_card ">
                                <div className="sign-in-page-data">
                                    <div className=" w-100 m-auto not-center"  >
                                        <div className='text-center'>
                                        <img
                                                className="img-fluid logo "
                                                src={logo}
                                                alt="streamit"
                                            />
                                        </div>
                                        <p className="text-body">Esqueceu a senha?</p>
                                        <Form onSubmit={TryToSaveBasicData} className="mt-4">
                                            <div className="form-group">                                 
                                                <input type="password" name="newpassword" id="newpassword" className="form-control mb-0" placeholder="Informe a nova senha" autoComplete="off" required/>
                                            </div>                           
                                            <div className="sign-info">
                                                <Button type="submit" className="btn btn-block font-weight-bold btn-hover btn-primary1">Alterar Senha</Button>                                                            
                                            </div>                                       
                                        </Form>
                                        <hr className='CustomHR'/>
                                        <p className='text-center CustomText'>
                                            Faça seu cadastro e <br/>conquiste o American Dream
                                        </p>
                                        <div className="sign-info">
                                                <a href='/auth/register' className="btn btn-block font-weight-bold btnCustom ">Criar Conta</a>                                                            
                                         </div>    
                                         <p className='text-center CustomText mt-3'>
                                         &#8226; Precisa de ajuda?
                                        </p>
                                    </div>
                                </div>                    
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default  RecoverPswd