import React, { useEffect, useState } from "react";
import { gsap } from "gsap";
import { Link } from "react-router-dom";
import { Container, Col, Row, Nav, Tab } from "react-bootstrap";
import FsLightbox from "fslightbox-react";
import Select from "react-select";

// img
import dark from "../../../assets/images/dark.png";
import stars from "../../../assets/images/stars.png";
import food from "../../../assets/images/food.png";
import logo from "../../../assets/images/logo.png";
import icon from "../../../assets/video/trailer.mp4";

// favorite img
import fav1 from "../../../assets/images/favorite/01.jpg";
import fav2 from "../../../assets/images/favorite/02.jpg";
import fav3 from "../../../assets/images/favorite/03.jpg";
import fav4 from "../../../assets/images/favorite/04.png";
import fav5 from "../../../assets/images/favorite/05.jpg";

// upcoming img
import upcoming1 from "../../../assets/images/upcoming/01.jpg";
import upcoming2 from "../../../assets/images/upcoming/02.jpg";
import upcoming3 from "../../../assets/images/upcoming/03.jpg";
import upcoming4 from "../../../assets/images/upcoming/04.jpg";
import upcoming5 from "../../../assets/images/upcoming/05.jpg";

// suggested
import suggested1 from "../../../assets/images/suggested/01.jpg";
import suggested2 from "../../../assets/images/suggested/02.jpg";
import suggested3 from "../../../assets/images/suggested/03.jpg";
import suggested4 from "../../../assets/images/suggested/04.jpg";
import suggested5 from "../../../assets/images/suggested/05.jpg";

// parallax
import parallax3 from "../../../assets/images/parallax/p1.jpg";
import parallax4 from "../../../assets/images/parallax/parallax-logo.png";

// trending
import trending1 from "../../../assets/images/trending/01.jpg";
import trending2 from "../../../assets/images/trending/02.jpg";
import trending3 from "../../../assets/images/trending/03.jpg";
import trending4 from "../../../assets/images/trending/04.jpg";
import trending5 from "../../../assets/images/trending/05.jpg";
import trending6 from "../../../assets/images/trending/06.jpg";
import trendinglabel from "../../../assets/images/trending/trending-label.png";

// episodes
import episodes1 from "../../../assets/images/episodes/01.jpg";
import episodes2 from "../../../assets/images/episodes/02.jpg";
import episodes3 from "../../../assets/images/episodes/03.jpg";
import episodes4 from "../../../assets/images/episodes/04.jpg";
import episodes5 from "../../../assets/images/episodes/05.jpg";

// tvthrillers
import tvthrillers1 from "../../../assets/images/tvthrillers/01.jpg";
import tvthrillers2 from "../../../assets/images/tvthrillers/02.jpg";
import tvthrillers3 from "../../../assets/images/tvthrillers/03.jpg";
import tvthrillers4 from "../../../assets/images/tvthrillers/04.jpg";
import tvthrillers5 from "../../../assets/images/tvthrillers/05.jpg";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from "swiper";
import "swiper/swiper-bundle.css";

import SerieTable from "../../../database/table/series";
import Category from "../../../database/table/category";

SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

const TableSeries = new SerieTable();

const gsapAnimate = {
	getData: (elem) => {
		const option = {
			opacity: 0,
			scale: 1,
			position: {
				x: 0,
				y: 0,
			},
			ease: "",
			duration: 1,
			delay: 0.4,
			rotate: 0,
		};
		if (elem !== undefined) {
			option.position.x = gsapAnimate.validValue(
				elem.dataset.iqPositionX,
				0
			);

			option.position.y = gsapAnimate.validValue(
				elem.dataset.iqPositionY,
				0
			);

			option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0);

			option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1);

			option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0);

			option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, 0.4);

			option.duration = gsapAnimate.validValue(
				elem.dataset.iqDuration,
				1.5
			);

			option.ease = gsapAnimate.validValue(elem.dataset.iqEase, "");

			const setOption = {
				opacity: option.opacity,
				scale: option.scale,
				x: option.position.x,
				y: option.position.y,
				ease: option.ease,
				rotate: option.rotate,
				duration: option.duration,
				delay: option.delay,
			};

			return setOption;
		} else {
			return { opacity: 0 };
		}
	},
	onStart: (elem) => {
		const setOption = gsapAnimate.getData(elem);

		gsap.from(elem, setOption);
	},

	onEnd: (elem) => {
		const setOption = gsapAnimate.getData(elem);

		gsap.to(elem, setOption);
	},

	onStartEnd: (elem) => {
		const setOption = gsapAnimate.getData(elem);

		const setEndOption = gsapAnimate.getData(elem);

		setEndOption.opacity = 1;

		setEndOption.x = 0;

		setEndOption.y = 0;

		setEndOption.rotate = 0;

		setEndOption.scale = 1;

		gsap.fromTo(elem, setOption, setEndOption);
	},
	validValue: (attr, defaultVal) => {
		if (attr !== undefined && attr !== null) {
			return Number(attr);
		}
		return Number(defaultVal);
	},
};

const Homepage = ({ match }) => {
	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	const [toggler1, setToggler1] = useState(false);
	const [toggler2, setToggler2] = useState(false);
	const [toggler3, setToggler3] = useState(false);

	const animationInit = () => {
		if (
			document.querySelector(".swiper-container .swiper-slide-active") !==
			null
		) {
			const gsapElem = document
				.querySelector(".swiper-container .swiper-slide-active")
				.querySelectorAll('[data-iq-gsap="onStart"]');

			Array.from(gsapElem, (elem) => {
				return gsapAnimate.onStartEnd(elem);
			});
		}
	};

	const [movies, setMovies] = useState([]);
	useEffect(() => {
		TableSeries.find(match.params.s).then((res) => {
			setMovies(res.results);
		});
	}, []);

	const url = process.env.REACT_APP_URL;

	return (
		<>
			<FsLightbox
				toggler={toggler1}
				sources={[
					<iframe
						src={icon}
						title=" "
						width="500px"
						height="200px"
						frameBorder="0"
						allow="autoplay; fullscreen"
						allowFullScreen
					/>,
				]}
			/>
			<FsLightbox
				toggler={toggler2}
				sources={[
					<iframe
						src={icon}
						title=" "
						width="500px"
						height="200px"
						frameBorder="0"
						allow="autoplay; fullscreen"
						allowFullScreen
					/>,
				]}
			/>
			<FsLightbox
				toggler={toggler3}
				sources={[
					<iframe
						src={icon}
						title=" "
						width="500px"
						height="200px"
						frameBorder="0"
						allow="autoplay; fullscreen"
						allowFullScreen
					/>,
				]}
			/>
		

			<div className="main-content">
				<section id="iq-suggestede" className="s-margin pb-5 pt-5">
					<Container fluid>
                    <h2 className="text-center pt-2">Resultados de:</h2>
                    <h4 className="text-center pt-1 pb-4">"{match.params.s}"</h4>
						<Row>
							<Col sm="12" className="cards-mec overflow-hidden">
					
									<div className="row">
										{movies
											.map((movie, index) => {
												if (
													match.params.id == null ||
													movie.descategory ==
														match.params.id
												) {
													return (
                                                        <div className="col-md-3 col-sm-6 pb-4">
                                                        	<SwiperSlide className="col align-items-center" as="li">
															<Link to={`/movie-details/${movie.id}`}>
															<div className="block-images position-relative">
																<div className="img-box">
																	<img
																		src={`${url}/api/user/profile/${movie.desimage}`}
																		className="img-fluid"
																		alt=""
																	/>
																</div>
															</div>
															</Link>
                                                            <div class="divider"></div>
                                                            </SwiperSlide>
                                                        </div>
													);
												}
											})}
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			</div>
		</>
	);
};

export default Homepage;
