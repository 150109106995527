
import functionary from "./json/functionary.json";
export default class Routes{



    /**
     * @param {*} UserLevel
     * @return {object}
     */
    getRoutes(UserLevel) {
        let routes = [];

        functionary.forEach(element => {
            if(element.auth <= UserLevel){
                //check if element has "submenu"
                if(element.submenu){
                    element.submenu.forEach(submenu => {
                        //check if submenu.auth <= UserLevel
                        if(submenu.auth > UserLevel){
                            //remove item from element.submenu
                            element.submenu.splice(element.submenu.indexOf(submenu), 1);
                        }
                    });
                } 
                routes.push(element);
            }
        });

        return routes;
    }



}