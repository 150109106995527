import {Switch,Route} from 'react-router-dom'

import SignUp from '../views/backend/pages/auth/signup'
import Login from '../views/backend/pages/auth/login'
import Video from '../views/backend/pages/video'
import RecoverPswd from '../views/backend/pages/auth/recover-pswd'
import RecoverPswdSimple from '../views/backend/pages/auth/recover-pswdsimple'


import ProtectedRoutes from "./auth/ProtectedRoutes";


const ExtraPages = () => {

    return (

        <Switch>
            <Route path="/extra-pages/watch-video"      component={Video} />
            <Route path="/auth/recover/change/:id"      component={RecoverPswd} />
            <Route path="/auth/recover"                 component={RecoverPswdSimple} />

            <ProtectedRoutes path="/auth/register" IsAuthRoute component={SignUp} />
            <ProtectedRoutes path="/auth/login" IsAuthRoute component={Login}  />

        </Switch>
        
    )

}

export default ExtraPages