import React, { useEffect, useState } from "react";
import VideoJS from "../../../VideoJS";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router";
//img
import episode1 from "../../../assets/images/episodes/01.jpg";
import episode2 from "../../../assets/images/episodes/02.jpg";
import episode3 from "../../../assets/images/episodes/03.jpg";
import episode4 from "../../../assets/images/episodes/04.jpg";
import episode5 from "../../../assets/images/episodes/05.jpg";
import episode6 from "../../../assets/images/episodes/06.jpg";
import episode7 from "../../../assets/images/episodes/07.jpg";
import episode8 from "../../../assets/images/episodes/08.jpg";
import episode9 from "../../../assets/images/episodes/09.jpg";
import episode10 from "../../../assets/images/episodes/10.jpg";

import MovieTable from "../../../database/table/movie";
import ListRelatedMovies from "../../../database/table/related";
import JsonWebToken from "../../../router/auth/JsonWebToken";
import axios from "axios";

import play from "../../../assets/images/play-button.png";
import current from "../../../assets/images/current.png";

let _jwt = new JsonWebToken(localStorage.getItem("auth"));

const ShowList = ({ match }) => {
  const playerRef = React.useRef(null);
  const TableMovie = new MovieTable();
  const TableRelated_ = new ListRelatedMovies();
  const [movies, setMovies] = useState([]);
  const url = process.env.REACT_APP_URL;

  const [users, setUsers] = useState([]);
  const [series, setSeries] = useState([]);
  const [idmovie, setid] = useState([]);

  let next, currentSerie, currentSeason, currentEpisode;
  next = currentSerie = currentSeason = currentEpisode = 0;

  useEffect(() => {
    // scroll to the top of the page
    window.scrollTo(0, 0);

    TableMovie.get(match.params.id).then((res) => {
      setMovies(res[0]);

      currentSerie = res[0].idserie;
      currentSeason = res[0].desseason;
      currentEpisode = res[0].desepisode;

      playerRef.current.src([
        {
          src: url + "/api/movie/view/" + res[0].desvideo,
          type: "video/mp4",
        },
      ]);
    });

    TableMovie.get().then((res) => {
      setUsers(res);

      res.map((movie) => {
        if (movie.idserie == currentSerie) {
          if (
            movie.desseason == currentSeason &&
            movie.desepisode == currentEpisode + 1
          )
            next = movie.id;
          else if (
            movie.desseason == currentSeason + 1 &&
            movie.desepisode == 1
          )
            next = movie.id;
        }
      });
    });

    TableRelated_.getRelated(match.params.id).then((res) => {
      if (res.length < 0) {
        window.location.href = `/`;
      }
      //get first item of array
      const firstItem = res[0];
      //get id of first item

      setid(firstItem.id);
      setSeries(res);
    });
  }, []);

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: false,
    fill: true,
    playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
    userActions: {
      hotkeys: {
        muteKey: function (event) {
          // 'm' key to mute
          return event.which === 77;
        },
        fullscreenKey: function (event) {
          // 'f' key to fullscreen
          return event.which === 70;
        },
        playPauseKey: function (event) {
          // 'space' key to play/pause
          return event.which === 32;
        },
      },
    },
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    function drawResolutionMenu(narg) {
      if (document.querySelector(".vjs-resolution") != null)
        document.querySelector(".vjs-resolution").remove();
      const resolution = player.getChild("ControlBar").addChild("button", {
        clickHandler: function (event) {
          const nhd = resolution.addChild("button", {
            clickHandler: function (event) {
              TableMovie.get(match.params.id).then((res) => {
                player.src([
                  {
                    src:
                      url +
                      "/api/movie/view/" +
                      res[0].desvideo.replace(".mp4", "-360p.mp4"),
                    type: "video/mp4",
                  },
                ]);
              });
              drawResolutionMenu("360p");
            },
          });
          const pal = resolution.addChild("button", {
            clickHandler: function (event) {
              TableMovie.get(match.params.id).then((res) => {
                player.src([
                  {
                    src:
                      url +
                      "/api/movie/view/" +
                      res[0].desvideo.replace(".mp4", "-480p.mp4"),
                    type: "video/mp4",
                  },
                ]);
              });
              drawResolutionMenu("480p");
            },
          });
          const hd = resolution.addChild("button", {
            clickHandler: function (event) {
              TableMovie.get(match.params.id).then((res) => {
                player.src([
                  {
                    src:
                      url +
                      "/api/movie/view/" +
                      res[0].desvideo.replace(".mp4", "-720p.mp4"),
                    type: "video/mp4",
                  },
                ]);
              });
              drawResolutionMenu("720p");
            },
          });
          const fullhd = resolution.addChild("button", {
            clickHandler: function (event) {
              TableMovie.get(match.params.id).then((res) => {
                player.src([
                  {
                    src: url + "/api/movie/view/" + res[0].desvideo,
                    type: "video/mp4",
                  },
                ]);
              });
              drawResolutionMenu("1080p");
            },
          });
          fullhd.addClass("vjs-fullhd");
          document.querySelector(".vjs-fullhd").innerHTML = "1080p";
          hd.addClass("vjs-hd");
          document.querySelector(".vjs-hd").innerHTML = "720p";
          pal.addClass("vjs-pal");
          document.querySelector(".vjs-pal").innerHTML = "480p";
          nhd.addClass("vjs-nhd");
          document.querySelector(".vjs-nhd").innerHTML = "360p";
        },
      });
      resolution.addClass("vjs-visible-text");
      resolution.addClass("vjs-resolution");
      document.querySelector(".vjs-resolution").innerHTML = narg;
    }

    drawResolutionMenu("1080p");

    let currentTime = player.currentTime();
    // let temp = player.currentTime() + 1;
    const overlay = player.addChild("button", {});
    overlay.addClass("vjs-overlay");

    axios
      .get(url + "/api/movie/check/" + _jwt.getId() + "/" + match.params.id)
      .then((res) => {
        player.currentTime(res.data.desprogress);
      });

    player.on("timeupdate", function () {
      if (player.currentTime() > currentTime + 1)
        currentTime = parseInt(player.currentTime());

      let temp = 0;
      if (currentTime % 10 == 0 && temp < currentTime) {
        temp = currentTime + 1;
        axios.post(url + "/api/movie/progress", {
          idmovie: match.params.id,
          iduser: _jwt.getId(),
          desprogress: temp,
        });
      }

      if (currentTime >= parseInt(player.duration()) - 5) {
        document.querySelector(".vjs-overlay").innerHTML =
          "Próxima aula em " +
          (parseInt(player.duration()) - currentTime) +
          " segundos...";
      }
    });

    player.on("ended", async function () {
      await axios.post(url + "/api/movie/progress/done", {
        idmovie: match.params.id,
        iduser: _jwt.getId(),
      });
      if (next != 0) {
        window.location.href = "/show-details/" + next;
      }
    });
  };

  return (
    <>
      <div className="video-wrapper">
        <div className="video-container">
          <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
        </div>
        <div className="next-box">
          <h4 className="mb-3 font-weight-bold">Próximos episódios</h4>
          <img src={current} className="current-episode"></img>
          {/* exclude lesser id */}
          {series
            .filter((item) => item.id >= match.params.id)
            .sort((a, b) => a.id - b.id)
            .map((movie) => {
              return (
                <Row className="episodes-wrapper">
                  <div className="episode-box">
                    <Link to={`/show-details/${movie.id}`}>
                      <img src={play}></img>
                    </Link>
                    <div className="description">
                      <h4 className="mb-2">{movie.desname}</h4>
                      <p>{movie.desdescription}</p>
                    </div>
                  </div>
                </Row>
              );
            })}
        </div>
      </div>
      <div className="main-content">
        <section className="movie-detail container-fluid">
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </section>
      </div>
    </>
  );
};
export default ShowList;
