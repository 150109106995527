import React from 'react'
import { Container, Row, Col,Breadcrumb } from 'react-bootstrap'
import { Link } from 'react-router-dom'

//img
import aboutstars from '../../../assets/images/Estrelas.png'

const AboutUs= () => {
   return (
      <>
        <main id="main" className="site-about">
            <Container fluid className="p-0" >
               <div className="map-container-about-us" style={{ left: "0px"}}>
                  <Container>
                     <Row className="about-us-row text-center align-items-center">
                        <Col md="2"></Col>
                        <Col md="8">
                           <div className=" text-md-left iq-title-box iq-title-default">
                              <h2 className="iq-title font-weight-bold">
                                 Sobre nós  <img src={aboutstars} alt="aboutstars" className="bgstars" />
                              </h2>                        
                              <p className="iq-title-desc" style={{ color:"white" }}>Somos a American Dream Class, a plataforma de aprendizado inovadora, que através de conteúdos em formato de episódios, vai ajudar inúmeros brasileiros a alcançarem o sucesso!</p>                     
                              <br/>
                              <p style={{ color:"white" }}>Nossos cursos são focados no que realmente é importante para as pessoas e diferente dos demais, na American Dream Class você aprenderá com mentores que já vivenciaram o sonho americano.</p>
                           </div>
                        </Col>
                        <Col md="2"></Col>
                     </Row>
                  </Container>               
               </div>
            </Container>
      </main>
      </>
   )
}
export default AboutUs;