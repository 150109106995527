import axios from 'axios';

export default class Attachments {
    //criar um constructor que irá receber parametros como página atual, registros por página, 
    //total de registros, total de páginas, etc.
    constructor(page, perPage) {
        //url from API
        this.root = process.env.REACT_APP_URL;
        //Página Atual
        this.page = page;
        //Resultados por Página
        this.perPage = perPage;
    }

    /**
    * @description Essa função irá retornar os dados do usuário
    * @returns {Promise}
    */
    async get(id = null) {
        if(id == null) {
            return new Promise((resolve, reject) => {
                axios.get(`${this.root}/api/attachments/list`, {
                    params: {
                        page: 1,
                        limit: 2
                    }
                })
                .then(res => {
                        resolve(res.data);
                })
                .catch(err => {
                        reject(err);
                })
            })
        }else{
            return new Promise((resolve, reject) => {
                axios.get(`${this.root}/api/attachments/list/${id}`)
                .then(res => {
                        resolve(res.data);
                })
                .catch(err => {
                        reject(err);
                })
            })
        }
    }

     /**
    * @description Essa função irá retornar os dados do usuário
    * @returns {Promise}
    */
      async getUnique(id = null) {
        return new Promise((resolve, reject) => {
            axios.get(`${this.root}/api/attachments/unique/${id}`)
            .then(res => {
                    resolve(res.data);
            })
            .catch(err => {
                    reject(err);
            })
        })
    }
    
}