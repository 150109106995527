
import jwt from "jsonwebtoken";
import axios from "axios";
export default class JsonWebToken {

    /**
     * @param {*} token
     * @returns {null}
     */
    constructor(token) {
        this.token = token;
    }



    getId() {
        return jwt.decode(this.token).id;
    }


    /**
     * @param {Object} props
     * @returns {bool}
    */
    validateToken() {
        return jwt.verify(this.token, "Sapato_Sandalha_Sanduicheira0$!", (err, decoded) => {
            if (err) {
                    //check if user is logged in
                    if (jwt.decode(this.token)) {
                        axios.post("http://localhost:3000/api/refreshToken", {
                            token: this.token
                        }).then(res => {
                            if (res.data.success) {
                                this.token = res.data.token;
                                return true;
                            } else {
                                return false;
                            }
                        }).catch(err => {
                            return false;
                        });
                    }else{
                        return false;
                    }
            } else {
                return true;
            }
        });
    }





   /**
     * @returns {object}
    */
    retrieveData(IsLogged = false) {

        //check if token is different than null, and if it is valid
        if (this.token && IsLogged) {
            return jwt.decode(this.token);
        }   
        else {
            return false;
        }
    }


   async retrieveAllData(IsLogged = false) {

           //check if token is different than null, and if it is valid
           if (this.token && IsLogged) {
               let decoded = jwt.decode(this.token);
               let id = decoded.id;

               return await axios.get(`${process.env.REACT_APP_URL}/api/user/get/${id}`)
           }
           else {
               return false;
           }

    }




}