import React from "react";
import { Route, Redirect } from "react-router-dom";
import jwt from "jsonwebtoken";

import JsonWebToken from "./JsonWebToken";

// receives component and any other props represented by ...rest
export default function ProtectedRoutes({ component: Component, SecureLock, ...rest }) {
  return (
    // this route takes other route assigned to it from the App.js and return the same route if condition is met
    <Route
      {...rest}
      render={(props) => {

        let _token = new JsonWebToken(localStorage.getItem("auth"));

        if(SecureLock){
          if( _token.validateToken() && _token.retrieveData(true).desaccess >= 1 ){
            return <Component {...props} />;
          }
          else{
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          }
        }else{
            return (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              );
        }




      }}
    />
  );
}