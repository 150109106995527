import React, { useEffect, useState } from "react";
import { gsap } from "gsap";
import { Link } from "react-router-dom";
import { Container, Col, Row, Nav, Tab } from "react-bootstrap";
import FsLightbox from "fslightbox-react";
import Select from "react-select";

// img
import dark from "../../../assets/images/dark.png";
import stars from "../../../assets/images/stars.png";
import food from "../../../assets/images/food.png";
import logo from "../../../assets/images/logo.png";
import icon from "../../../assets/video/trailer.mp4";

// favorite img
import fav1 from "../../../assets/images/favorite/01.jpg";
import fav2 from "../../../assets/images/favorite/02.jpg";
import fav3 from "../../../assets/images/favorite/03.jpg";
import fav4 from "../../../assets/images/favorite/04.png";
import fav5 from "../../../assets/images/favorite/05.jpg";

// upcoming img
import upcoming1 from "../../../assets/images/upcoming/01.jpg";
import upcoming2 from "../../../assets/images/upcoming/02.jpg";
import upcoming3 from "../../../assets/images/upcoming/03.jpg";
import upcoming4 from "../../../assets/images/upcoming/04.jpg";
import upcoming5 from "../../../assets/images/upcoming/05.jpg";

// suggested
import suggested1 from "../../../assets/images/suggested/01.jpg";
import suggested2 from "../../../assets/images/suggested/02.jpg";
import suggested3 from "../../../assets/images/suggested/03.jpg";
import suggested4 from "../../../assets/images/suggested/04.jpg";
import suggested5 from "../../../assets/images/suggested/05.jpg";

// parallax
import parallax3 from "../../../assets/images/parallax/p1.jpg";
import parallax4 from "../../../assets/images/parallax/parallax-logo.png";

// trending
import trending1 from "../../../assets/images/trending/01.jpg";
import trending2 from "../../../assets/images/trending/02.jpg";
import trending3 from "../../../assets/images/trending/03.jpg";
import trending4 from "../../../assets/images/trending/04.jpg";
import trending5 from "../../../assets/images/trending/05.jpg";
import trending6 from "../../../assets/images/trending/06.jpg";
import trendinglabel from "../../../assets/images/trending/trending-label.png";

// episodes
import episodes1 from "../../../assets/images/episodes/01.jpg";
import episodes2 from "../../../assets/images/episodes/02.jpg";
import episodes3 from "../../../assets/images/episodes/03.jpg";
import episodes4 from "../../../assets/images/episodes/04.jpg";
import episodes5 from "../../../assets/images/episodes/05.jpg";

// tvthrillers
import tvthrillers1 from "../../../assets/images/tvthrillers/01.jpg";
import tvthrillers2 from "../../../assets/images/tvthrillers/02.jpg";
import tvthrillers3 from "../../../assets/images/tvthrillers/03.jpg";
import tvthrillers4 from "../../../assets/images/tvthrillers/04.jpg";
import tvthrillers5 from "../../../assets/images/tvthrillers/05.jpg";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from "swiper";
import "swiper/swiper-bundle.css";

import SerieTable from "../../../database/table/series";
import Category from "../../../database/table/category";

import JsonWebToken from '../../../router/auth/JsonWebToken';

let _jwt = new JsonWebToken(localStorage.getItem("auth"));

SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

const TableSeries = new SerieTable();

const gsapAnimate = {
	getData: (elem) => {
		const option = {
			opacity: 0,
			scale: 1,
			position: {
				x: 0,
				y: 0,
			},
			ease: "",
			duration: 1,
			delay: 0.4,
			rotate: 0,
		};
		if (elem !== undefined) {
			option.position.x = gsapAnimate.validValue(
				elem.dataset.iqPositionX,
				0
			);

			option.position.y = gsapAnimate.validValue(
				elem.dataset.iqPositionY,
				0
			);

			option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0);

			option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1);

			option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0);

			option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, 0.4);

			option.duration = gsapAnimate.validValue(
				elem.dataset.iqDuration,
				1.5
			);

			option.ease = gsapAnimate.validValue(elem.dataset.iqEase, "");

			const setOption = {
				opacity: option.opacity,
				scale: option.scale,
				x: option.position.x,
				y: option.position.y,
				ease: option.ease,
				rotate: option.rotate,
				duration: option.duration,
				delay: option.delay,
			};

			return setOption;
		} else {
			return { opacity: 0 };
		}
	},
	onStart: (elem) => {
		const setOption = gsapAnimate.getData(elem);

		gsap.from(elem, setOption);
	},

	onEnd: (elem) => {
		const setOption = gsapAnimate.getData(elem);

		gsap.to(elem, setOption);
	},

	onStartEnd: (elem) => {
		const setOption = gsapAnimate.getData(elem);

		const setEndOption = gsapAnimate.getData(elem);

		setEndOption.opacity = 1;

		setEndOption.x = 0;

		setEndOption.y = 0;

		setEndOption.rotate = 0;

		setEndOption.scale = 1;

		gsap.fromTo(elem, setOption, setEndOption);
	},
	validValue: (attr, defaultVal) => {
		if (attr !== undefined && attr !== null) {
			return Number(attr);
		}
		return Number(defaultVal);
	},
};

const Homepage = ({ match }) => {
	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	const [toggler1, setToggler1] = useState(false);
	const [toggler2, setToggler2] = useState(false);
	const [toggler3, setToggler3] = useState(false);

	const animationInit = () => {
		if (
			document.querySelector(".swiper-container .swiper-slide-active") !==
			null
		) {
			const gsapElem = document
				.querySelector(".swiper-container .swiper-slide-active")
				.querySelectorAll('[data-iq-gsap="onStart"]');

			Array.from(gsapElem, (elem) => {
				return gsapAnimate.onStartEnd(elem);
			});
		}
	};

	const [movies, setMovies] = useState([]);
	useEffect(() => {
		TableSeries.get().then((res) => {
			setMovies(res);
		});
	}, []);

	const [KeepWatchingMovies, setKeepWatchingMovies] = useState([]);
	useEffect(() => {
		TableSeries.getKeepWatching(_jwt.getId()).then((res) => {
			setKeepWatchingMovies(res);
		});
	}, []);

	const url = process.env.REACT_APP_URL;

	return (
		<>
			<FsLightbox
				toggler={toggler1}
				sources={[
					<iframe
						src={icon}
						title=" "
						width="500px"
						height="200px"
						frameBorder="0"
						allow="autoplay; fullscreen"
						allowFullScreen
					/>,
				]}
			/>
			<FsLightbox
				toggler={toggler2}
				sources={[
					<iframe
						src={icon}
						title=" "
						width="500px"
						height="200px"
						frameBorder="0"
						allow="autoplay; fullscreen"
						allowFullScreen
					/>,
				]}
			/>
			<FsLightbox
				toggler={toggler3}
				sources={[
					<iframe
						src={icon}
						title=" "
						width="500px"
						height="200px"
						frameBorder="0"
						allow="autoplay; fullscreen"
						allowFullScreen
					/>,
				]}
			/>
			<section id="home" className="iq-main-slider p-0 iq-rtl-direction">
				<Swiper
					pagination={{
						clickable: true,
					}}
					onInit={() => {
						animationInit();
					}}
					onSlideChangeTransitionStart={() => animationInit()}
					loop={true}
					id="home-slider"
					className="slider m-0 p-0">
					{movies.map((movie, index) => {
						if (
							movie.descapa == 1 &&
							match.params.id == null ||
							movie.descategory == match.params.id
						) {
							return (
								<SwiperSlide
									className="slide slick-bg s-bg-1"
									style={{
										backgroundImage: `url(${url}/api/user/profile/${movie.desbanner})`,
									}}>
									<Container
										fluid
										className="position-relative h-100 container-wrapper">
										<div className="slider-inner h-100">
											<Row className="align-items-center  iq-ltr-direction h-100 ">
												<Col xl="6" lg="12" md="12">
														<div className="text-primary title starring">
															<div className="text-primary title">
																{JSON.parse(
																	movie.desactors
																).map(
																	(actor) => (
																		//if is the last actor, dont add comma
																		<h5 className="text-body">
																			{" "}
																			{
																				actor.label
																			}
																			{" "}
																		</h5>
																	)
																)}
															</div>
														</div>
													<h1
														className="slider-text big-title title"
														data-iq-gsap="onStart"
														data-iq-position-x="-200">
														{movie.desname}
													</h1>
													<div className="d-flex flex-wrap align-items-center container-description">
														<p
															data-iq-gsap="onStart"
															data-iq-position-y="80"
															data-iq-delay="0.8">
															{
																movie.desdescription
															}
														</p>
													</div>
													<div
														className="d-flex align-items-center r-mb-23 btn-wrapper"
														data-iq-gsap="onStart"
														data-iq-position-y="80"
														data-iq-delay="0.8">
														<Link
															to={`/movie-details/${movie.id}`}
															className="font-weight-bold btn btn-hover iq-button"
															>
															Assista agora
														</Link>
														{/* <Link to="/show-details" className="btn btn-link">More details</Link> */}
													</div>
												</Col>
												<div className="content-upper-modal">
                                <h3 className="font-weight-bold">Início</h3>
                                <h3 className="font-weight-light">Minha lista</h3>
                                <h3 className="font-weight-light">Novidades</h3>
                                <h3 className="font-weight-light d-none d-lg-block">lorem ipsum</h3>
                                <h3 className="font-weight-light d-none d-lg-block">lorem ipsum</h3>
                            </div>
											</Row>
										</div>
									</Container>
								</SwiperSlide>
							);
						}
					})}
				</Swiper>
			</section>

			<div className="main-content">

					{KeepWatchingMovies.length > 0 && (
						<section id="iq-suggestede" className="s-margin">
							<Container fluid>
								<Row>
									<Col sm="12" className="cards-mec overflow-hidden  watching">
						
											<div>
											<h4 className="main-title">
												Continue assistindo
											</h4>
										</div>
										<div id="suggestede-contens">
											<div
												className="swiper-button swiper-button-prev">
												<i id="prev12" className="fa fa-chevron-left"></i>
											</div>
											<div
												className="swiper-button swiper-button-next">
												<i id="next12" className="fa fa-chevron-right"></i>
											</div>
											<Swiper
												slidesPerView={5}
												spaceBetween={20}
												navigation={{
													prevEl: "#prev12",
													nextEl: "#next12",
												}}
												breakpoints={{
													320: { slidesPerView: 1.5 },
													550: { slidesPerView: 2.5 },
													991: { slidesPerView: 3.5 },
													1400: { slidesPerView: 4.5 },
												}}
												loop={false}
												as="ul"
												className="list-inline favorites-slider row p-0 m-0 iq-rtl-direction">
												{KeepWatchingMovies.map((movie, index) => {
													if (
														match.params.id == null ||
														movie.descategory ==
														match.params.id
													) {
														return (
															<SwiperSlide className="d-flex flex-column align-items-center" as="li">
																<Link to={`/show-details/${movie.idmovie}`}>
																<div className="block-images position-relative">
																	<div className="img-box">
																		<img
																			src={`${url}/api/user/profile/${movie.desimage}`}
																			className="img-fluid"
																			alt=""
																		/>
																	</div>
																</div>
																</Link>
																<div class="play-divider" 
																//set css variabele with style
																style={{'--divider': `${movie.progress}%`}}></div>
															</SwiperSlide>
														);
													}
												})}
											</Swiper>
										</div>
									</Col>
								</Row>
							</Container>
						</section>
					)}




				<section id="iq-suggestede" className="s-margin">
					<Container fluid>
						<Row>
							<Col sm="12" className="cards-mec overflow-hidden">
								<div>
									<h4 className="main-title">
										Documentação
									</h4>
									<p class="main-title-sub-heading">Aprenda tudo sobre a documentação necessária para lorem ipsum</p>
								</div>
								<div id="suggestede-contens">
									<div
										className="swiper-button swiper-button-prev">
										<i id="prev1" className="fa fa-chevron-left"></i>
									</div>
									<div
										className="swiper-button swiper-button-next">
										<i id="next1" className="fa fa-chevron-right"></i>
									</div>
									<Swiper
										slidesPerView={5}
										spaceBetween={20}
										navigation={{
											prevEl: "#prev1",
											nextEl: "#next1",
										}}
										breakpoints={{
											320: { slidesPerView: 1.5 },
											550: { slidesPerView: 2.5 },
											991: { slidesPerView: 3.5 },
											1400: { slidesPerView: 4.5 },
										}}
										loop={true}
										as="ul"
										className="list-inline favorites-slider row p-0 m-0 iq-rtl-direction">
										{movies
											.sort(() => Math.random() - 0.5)
											.slice(0, 4)
											.map((movie, index) => {
												if (
													match.params.id == null ||
													movie.descategory ==
														match.params.id
												) {
													return (
														<SwiperSlide className="d-flex flex-column align-items-center" as="li">
															<Link
																		to={`/movie-details/${movie.id}`}>
															<div className="block-images position-relative">

																<div className="img-box">

																	<img
																		src={`${url}/api/user/profile/${movie.desimage}`}
																		className="img-fluid"
																		alt=""
																	/>

																</div>

															</div>
															</Link>
														<div class="divider"></div>
														</SwiperSlide>
													);
												}
											})}
									</Swiper>
								</div>
							</Col>
						</Row>
					</Container>
				</section>

				<section id="iq-suggestede" className="s-margin">
					<Container fluid>
						<Row>
							<Col sm="12" className="cards-mec overflow-hidden">
								<div >
									<h4 className="main-title">
										Economia
									</h4>
									<p class="main-title-sub-heading">Aprenda tudo sobre a documentação necessária para lorem ipsum</p>
								</div>
								<div id="suggestede-contens">
									<div
										id="prev2"
										className="swiper-button swiper-button-prev">
										<i className="fa fa-chevron-left"></i>
									</div>
									<div
										id="next2"
										className="swiper-button swiper-button-next">
										<i className="fa fa-chevron-right"></i>
									</div>
									<Swiper
										slidesPerView={5}
										spaceBetween={20}
										navigation={{
											prevEl: "#prev2",
											nextEl: "#next2",
										}}
										breakpoints={{
											320: { slidesPerView: 1.5 },
											550: { slidesPerView: 2.5 },
											991: { slidesPerView: 3.5 },
											1400: { slidesPerView: 4.5 },
										}}
										loop={true}
										as="ul"
										className="list-inline favorites-slider row p-0 m-0 iq-rtl-direction">
										{movies
											.sort(() => Math.random() - 0.5)
											.slice(0, 4)
											.map((movie, index) => {
												if (
													match.params.id == null ||
													movie.descategory ==
														match.params.id
												) {
													return (
														<SwiperSlide className="d-flex flex-column align-items-center" as="li">
															<Link
																to={`/movie-details/${movie.id}`}>
															<div className="block-images position-relative">
																<div className="img-box">
																	<img
																		src={`${url}/api/user/profile/${movie.desimage}`}
																		className="img-fluid"
																		alt=""
																	/>
																</div>
															</div>
															</Link>
                                					    <div class="divider"></div>
														</SwiperSlide>
													);
												}
											})}
									</Swiper>
								</div>
							</Col>
						</Row>
					</Container>
				</section>

				<section id="iq-suggestede" className="s-margin">
					<Container fluid>
						<Row>
							<Col sm="12" className="cards-mec overflow-hidden">
								<div>
									<h4 className="main-title">
										Adaptabilidade Social
									</h4>
									<p class="main-title-sub-heading">Aprenda tudo sobre a documentação necessária para lorem ipsum</p>
								</div>
								<div id="suggestede-contens">
									<div
										id="prev3"
										className="swiper-button swiper-button-prev">
										<i className="fa fa-chevron-left"></i>
									</div>
									<div
										id="next3"
										className="swiper-button swiper-button-next">
										<i className="fa fa-chevron-right"></i>
									</div>
									<Swiper
										slidesPerView={5}
										spaceBetween={20}
										navigation={{
											prevEl: "#prev3",
											nextEl: "#next3",
										}}
										breakpoints={{
											320: { slidesPerView: 1.5 },
											550: { slidesPerView: 2.5 },
											991: { slidesPerView: 3.5 },
											1400: { slidesPerView: 4.5 },
										}}
										loop={true}
										as="ul"
										className="list-inline favorites-slider row p-0 m-0 iq-rtl-direction">
										{movies
											.sort(() => Math.random() - 0.5)
											.slice(0, 4)
											.map((movie, index) => {
												if (
													match.params.id == null ||
													movie.descategory ==
														match.params.id
												) {
													return (
														<SwiperSlide className="d-flex flex-column align-items-center" as="li">
															<Link
																to={`/movie-details/${movie.id}`}>
															<div className="block-images position-relative">
																<div className="img-box">
																	<img
																		src={`${url}/api/user/profile/${movie.desimage}`}
																		className="img-fluid"
																		alt=""
																	/>
																</div>
															</div>
															</Link>
                                    <div class="divider"></div>
														</SwiperSlide>
													);
												}
											})}
									</Swiper>
								</div>
							</Col>
						</Row>
					</Container>
				</section>


                <section
                        style={{
                            backgroundImage: `url()`,
                            "padding-top": `50px`
                        }}
						id="restaurant"
                >
                    <img
                        src={food}
                        alt="bailey"
                        style={{
                            "width": `100%`,
                            "padding-top": `50px`
                        }}
                    />
                    <div className="d-flex justify-content-center align-items-center parallax-buttons">
                        <Link
                            className="btn btn-hover big-btn d-none d-lg-block font-weight-bold" style={{"position" : `absolute`, "width": `18vw`, "height": `4vw`, "font-size": `1.5vw`, "color": `black`, "transform": `translate(90px, -150px)`}}>
                            Assista agora
                        </Link>
                    </div>
                </section>

				{/* { */}
				{/* 	//list  `${url}/api/user/profile/${movie.desimage}` */}
				{/* 	movies */}
				{/* 		.filter((movie) => movie.desdestaque == 2) */}
				{/* 		.slice(0, 1) */}
				{/* 		.map((movie, index) => { */}
				{/* 			if ( */}
				{/* 				match.params.id == null || */}
				{/* 				movie.descategory == match.params.id */}
				{/* 			) { */}
				{/* 				return ( */}
				{/* 					<section */}
				{/* 						id="parallex" */}
				{/* 						style={{ */}
				{/* 							backgroundColor: `red`, */}
				{/* 						}}> */}
				{/* 						<Container fluid style={{"overflow":`hidden`,"position":`relative`}} className="p-0"> */}
				{/* 											<img */}
				{/* 												src={stars} */}
				{/* 												alt="bailey" */}
    {/*                                                             className="stars" */}
				{/* 											/> */}
				{/* 							<Row className="align-items-center m-0"> */}
				{/* 								<Col lg="6" style={{"padding":`0`,}}> */}
				{/* 									<div> */}
				{/* 										<Link */}
				{/* 											to={`/movie-details/${movie.id}`}> */}
				{/* 											<img */}
				{/* 												src={`${url}/api/user/profile/${movie.desimage}`} */}
    {/*                                                             style={{width:`100%`,}} */}
				{/* 												alt="bailey" */}
				{/* 											/> */}
				{/* 										</Link> */}
				{/* 									</div> */}
				{/* 								</Col> */}
				{/* 								<Col lg="4" className="r-mb-23"> */}
				{/* 									<div> */}
				{/* 										<Link to="#"> */}
				{/* 											<h1 */}
				{/* 												className="text-uber slider-text big-title title text-uppercase text-center" */}
				{/* 												data-iq-gsap="onStart" */}
				{/* 												data-iq-position-x="-200"> */}
				{/* 												{movie.desname} */}
				{/* 											</h1> */}
				{/* 										</Link> */}
				{/* 										<p */}
    {/*                                                 className="pt-5 pb-2 text-center"> */}
				{/* 											{ */}
				{/* 												movie.desdescription */}
				{/* 											} */}
				{/* 										</p> */}
				{/* 										<div className="d-flex justify-content-center parallax-buttons"> */}
				{/* 											<Link */}
				{/* 												to={`/movie-details/${movie.id}`} */}
				{/* 												className="btn btn-hover big-btn"> */}
				{/* 												Assista agora */}
				{/* 											</Link> */}
				{/* 										</div> */}
				{/* 									</div> */}
				{/* 								</Col> */}
				{/* 							</Row> */}
				{/* 						</Container> */}
				{/* 					</section> */}
				{/* 				); */}
				{/* 			} */}
				{/* 		}) */}
				{/* } */}

			</div>
		</>
	);
};

export default Homepage;
