import axios from 'axios';


export default class Series {
  

    //criar um constructor que irá receber parametros como página atual, registros por página, 
    //total de registros, total de páginas, etc.
    constructor(page, perPage) {
        //url from API
        this.root = process.env.REACT_APP_URL;

        //Página Atual
        this.page = page;
        //Resultados por Página
        this.perPage = perPage;
    }


     /**
    * @description Essa função irá retornar os dados do usuário
    * @returns {Promise}
    */
      async get_detailed() {
            return new Promise((resolve, reject) => {
                axios.get(`${this.root}/api/serie/detailed/fetch`, {
                    params: {
                        page: 1,
                        limit: 2
                    }
                })
                .then(res => {
                        resolve(res.data);
                })
                .catch(err => {
                        reject(err);
                })
            })
    }



    /**
    * @description Essa função irá retornar os dados do usuário
    * @returns {Promise}
    */
    async get(id = null) {
        if(id == null) {
            return new Promise((resolve, reject) => {
                axios.get(`${this.root}/api/serie/fetch`, {
                    params: {
                        page: 1,
                        limit: 2
                    }
                })
                .then(res => {
                        resolve(res.data);
                })
                .catch(err => {
                        reject(err);
                })
            })
        }else{
            return new Promise((resolve, reject) => {
                axios.get(`${this.root}/api/serie/fetch/${id}`)
                .then(res => {
                        resolve(res.data);
                })
                .catch(err => {
                        reject(err);
                })
            })
        }
    }

     /**
        * @description Essa função irá retornar os episódios que o usuário já começou a asssistir
        * @returns {Promise}
    */
    async getKeepWatching(iduser = null) {
        return new Promise((resolve, reject) => {
            axios.get(`${this.root}/api/serie/keep/fetch`, {
                params: {
                    iduser: iduser,
                }
            })
            .then(res => {
                    resolve(res.data);
            })
            .catch(err => {
                    reject(err);
            })
        })
    }
        
    /**
        * @description Essa função irá retornar os dados do usuário
        * @returns {Promise}
        */
    async get_category(category = null) {
        if(category == null) {
            return new Promise((resolve, reject) => {
                axios.get(`${this.root}/api/serie/fetch`, {
                    params: {
                        page: 1,
                        limit: 2
                    }
                })
                .then(res => {
                        resolve(res.data);
                })
                .catch(err => {
                        reject(err);
                })
            })
        }else{
            return new Promise((resolve, reject) => {
                axios.get(`${this.root}/api/serie/category/${category}`, {
                    params: {
                        page: 1,
                        limit: 2
                    }
                })
                .then(res => {
                        resolve(res.data);
                })
                .catch(err => {
                        reject(err);
                })
            })
        }
    }

  /**
    * @description Essa função irá retornar os dados do usuário
    * @returns {Promise}
   */
   async check(name = null) {
    return new Promise((resolve, reject) => {
        axios.get(`${this.root}/api/serie/check`, {
            params: {
                desname: name
            }
        })
        .then(res => {
                resolve(res.data);
        })
        .catch(err => {
                reject(err);
        })
     })
    }

     /**
    * @description Essa função irá retornar os dados do usuário
    * @returns {Promise}
   */
   async find(name = null) {
    return new Promise((resolve, reject) => {
        axios.get(`${this.root}/api/serie/find`, {
            params: {
                desname: name
            }
        })
        .then(res => {
                resolve(res.data);
        })
        .catch(err => {
                reject(err);
        })
     })
    }

}{{  }}