import { Switch, Route, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import ProtectedRoutes from "./auth/ProtectedRoutes";

import PaymentArea from "../views/backend/pages/payment";



const PaymentRoute = () => {

    let location = useLocation();

    return (
        <TransitionGroup>
            <CSSTransition
            // key={location.key}
            classNames="fade"
            timeout={300}
            >
                <Switch  location={location}>
                   
                    {/* homepage */}
                    <ProtectedRoutes path="/signature/purchase"  component={PaymentArea}/>

                    {/* homepage */}
                    <ProtectedRoutes path="/signature/feedback"  component={PaymentArea}/>


                </Switch>
            </CSSTransition>
        </TransitionGroup>
    )
}

export default PaymentRoute
