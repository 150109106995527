import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Dropdown,
  Form,
  Nav,
} from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import Card from "../../../../components/Card";

import CustomToggle from "../../../../components/dropdowns";

import logo from "../../../../assets/images/logo.png";
import thumb1 from "../../../../assets/images/notify/thumb-1.jpg";
import thumb2 from "../../../../assets/images/notify/thumb-2.jpg";
import thumb3 from "../../../../assets/images/notify/thumb-3.jpg";
import user from "../../../../assets/images/user/user.jpg";

//Custom
import Routes from "../../../../router/routes/default";
import JsonWebToken from "../../../../router/auth/JsonWebToken";
import Logout from "../../../../views/backend/pages/auth/logout";
import Swal from "sweetalert2";
import CategoryTable from "../../../../database/table/category";
import SerieTable from "../../../../database/table/series";

let _JsonWebToken = new JsonWebToken(localStorage.getItem("auth"));
let isLogged = _JsonWebToken.validateToken();
let profileImage = user;

const CategoryTable_ = new CategoryTable();

const HeaderStyle1 = (props) => {
  const [categories, setCategories] = useState([]);
  const [small, setSmall] = useState(false);

  let [getRotas, setRotas] = useState([]);
  let _Rotas = new Routes();
  let TableSeries = new SerieTable();
  let history = useHistory();
  useEffect(() => {
    _JsonWebToken
      .retrieveAllData(isLogged)
      .then((_data) => {
        // get routes from Routes
        setRotas(_Rotas.getRoutes(_data.data[0].desaccess));
      })
      .catch((error) => {
        // if user is not logged in
      });

    CategoryTable_.get().then((res) => {
      setCategories(res.filter((category) => category.isactive === 1));
    });

    if (typeof window !== "undefined" && window.screen.width > 990) {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset > 10)
      );
    }
  }, []);

  return (
    <>
      <header id="main-header">
        <div className={`main-header ${small ? "small" : ""}`}>
          <Container fluid>
            <Row>
              <Col sm="12">
                <Navbar expand="lg" className="p-0">
                  <Navbar.Toggle className="c-toggler">
                    <div className="navbar-toggler-icon">
                      <span className="navbar-menu-icon navbar-menu-icon--top"></span>
                      <span className="navbar-menu-icon navbar-menu-icon--middle"></span>
                      <span className="navbar-menu-icon navbar-menu-icon--bottom"></span>
                    </div>
                  </Navbar.Toggle>
                  <Navbar.Brand className="navbar-brand">
                    <Link to="/">
                      <img
                        className="img-fluid logo"
                        src={logo}
                        alt="streamit"
                      />
                    </Link>
                  </Navbar.Brand>
                  <div className="mobile-more-menu navbar-right position-relative">
                    <ul className="d-flex align-items-center justify-content-end list-inline m-0">
                      <Dropdown as="li" className="nav-item m-">
                        <Dropdown.Toggle
                          href="#"
                          as={CustomToggle}
                          variant="search-toggle"
                        >
                          <i className="ri-search-line"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="search-box iq-search-bar d-search p-0 m-0 dropdown-menu-right">
                          <Form action="#" className="searchbox">
                            <div className="position-relative">
                              <input
                                type="text"
                                className="text search-input font-size-12"
                                placeholder="Pesquisar"
                              />
                              <i className="search-link ri-search-line"></i>
                            </div>
                          </Form>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown as="li" className="nav-item nav-icon">
                        <Dropdown.Toggle
                          href="#"
                          as={CustomToggle}
                          variant="search-toggle"
                        >
                          <div
                            className="iq-user-dropdown search-toggle p-0 d-flex align-items-center active"
                            data-toggle="search-toggle"
                          >
                            <img
                              src={user}
                              className="img-fluid avatar-40 rounded-circle"
                              alt="user"
                            />
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          className="iq-sub-dropdown iq-user-dropdown"
                          align="right"
                        >
                          <Card className="shadow-none m-0">
                            <Card.Body className="p-0 pl-3 pr-3">
                              {getRotas.map((item, index) => {
                                return (
                                  <Link
                                    to={item.path}
                                    className="iq-sub-card setting-dropdown"
                                  >
                                    <div className="media align-items-center">
                                      <div className="right-icon">
                                        <i className={item.icon}></i>
                                      </div>
                                      <div className="media-body ml-3">
                                        <h6 className="my-0 ">{item.name}</h6>
                                      </div>
                                    </div>
                                  </Link>
                                );
                              })}

                              <Link
                                onClick={Logout}
                                className="iq-sub-card setting-dropdown"
                              >
                                <div className="media align-items-center">
                                  <div className="right-icon">
                                    <i className="ri-logout-circle-line text-primary"></i>
                                  </div>
                                  <div className="media-body ml-3">
                                    <h6 className="my-0 ">Desconectar-se</h6>
                                  </div>
                                </div>
                              </Link>
                            </Card.Body>
                          </Card>
                        </Dropdown.Menu>
                      </Dropdown>
                    </ul>
                  </div>
                  <Navbar.Collapse className="">
                    <div className="menu-main-menu-container">
                      <Nav as="ul" id="top-menu" className="ml-auto">
                        {/* <li className="menu-item">
													<Link className={`menu-item`} to="#">Cursos</Link>
													<ul className="sub-menu">
														{categories.map(
															(category) => (
																<li className="menu-item">
																	<Link to={`/${category.id}`}>
																		{
																			category.desname
																		}
																	</Link>
																</li>
															)
														)}
													</ul>
												</li> */}
                        <li className="menu-item">
                          <Link className={`menu-item`} to="/courses">
                            Cursos
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link className={`menu-item`} to="/about-us">
                            Sobre Nós
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link className={`menu-item`} to="/contact">
                            Contato
                          </Link>
                        </li>
                      </Nav>
                    </div>
                  </Navbar.Collapse>
                  <div className="navbar-right menu-right">
                    <ul className="d-flex align-items-center list-inline m-0">
                      <Dropdown as="li" className="nav-item nav-icon">
                        <Dropdown.Toggle
                          as={CustomToggle}
                          href="#"
                          variant="search-toggle device-search"
                        >
                          <i className="ri-search-line"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          className="search-box iq-search-bar d-search p-0 m-0"
                          align="right"
                        >
                          <Form action="#" className="searchbox">
                            <div className="position-relative">
                              <input
                                type="text"
                                className="text search-input font-size-12"
                                placeholder="Pesquisar"
                              />
                              <i className="search-link ri-search-line"></i>
                            </div>
                          </Form>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown as="li" className="nav-item nav-icon">
                        <Dropdown.Toggle
                          href="#"
                          as={CustomToggle}
                          variant="search-toggle"
                        >
                          <div
                            className="iq-user-dropdown search-toggle p-0 d-flex align-items-center active"
                            data-toggle="search-toggle"
                          >
                            <img
                              src={user}
                              className="img-fluid avatar-40 rounded-circle"
                              alt="user"
                            />
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          className="iq-sub-dropdown iq-user-dropdown"
                          align="right"
                        >
                          <Card className="shadow-none m-0">
                            <Card.Body className="p-0 pl-3 pr-3">
                              {getRotas.map((item, index) => {
                                return (
                                  <Link
                                    to={item.path}
                                    className="iq-sub-card setting-dropdown"
                                  >
                                    <div className="media align-items-center">
                                      <div className="right-icon">
                                        <i className={item.icon}></i>
                                      </div>
                                      <div className="media-body ml-3">
                                        <h6 className="my-0 ">{item.name}</h6>
                                      </div>
                                    </div>
                                  </Link>
                                );
                              })}

                              <Link
                                onClick={Logout}
                                className="iq-sub-card setting-dropdown"
                              >
                                <div className="media align-items-center">
                                  <div className="right-icon">
                                    <i className="ri-logout-circle-line text-primary"></i>
                                  </div>
                                  <div className="media-body ml-3">
                                    <h6 className="my-0 ">Desconectar-se</h6>
                                  </div>
                                </div>
                              </Link>
                            </Card.Body>
                          </Card>
                        </Dropdown.Menu>
                      </Dropdown>
                    </ul>
                  </div>
                </Navbar>
              </Col>
            </Row>
          </Container>
        </div>
      </header>
    </>
  );
};

export default HeaderStyle1;
