import React,{useEffect, useState} from 'react'

import { Link} from 'react-router-dom'
import {Container,Row,Col,OverlayTrigger,Tooltip, Button} from 'react-bootstrap'
import Card  from '../../../components/Card'

import CategoryTable from '../../../database/table/category'

import Swal from 'sweetalert2'
import axios from 'axios'

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";



const AddNewCategory =() =>{
  
    Swal.fire({
        title: 'Nova Categoria',
        html: '<input id="swal-input1" placeholder="Nome da Categoria" class="swal2-input">' + 
        '<textarea id="swal-input2" placeholder="Descrição da Categoria"  class="swal2-input"></textarea>',
        focusConfirm: false,
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Cadastrar Categoria',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        showCancelButton: true,
        cancelButtonText: '<i class="fa fa-thumbs-down"></i> Cancelar',
        cancelButtonColor: '#d33',
        preConfirm: () => {
          return [
            document.getElementById('swal-input1').value,
            document.getElementById('swal-input2').value
          ]
        },
        }).then((result) => {
            //decode result
            const [name,description] = result.value
            if(name && description){
                  //swal with loading screen

                    const data = new FormData();
                    data.append("desname", name);
                    data.append("desdescription", description);
                    

                    Swal.fire({
                        title: 'Autenticando...',
                        icon: 'warning',
                        html: 'Aguarde enquanto validamos os dados...',
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: false,
                        didOpen: () => {
                            Swal.showLoading()
                            //Axios Call
                            axios({
                                method: 'post',
                                url: process.env.REACT_APP_URL + '/api/category/register',
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                    'Accept': 'application/json'
                                },
                                data : data
                            })
                            .then(function (response) {
                                    if(response.data){
                                        Swal.close()
                                        Swal.fire({
                                            title: 'Atualizado com Sucesso!',
                                            text: "Dados atualizados com sucesso!",
                                            icon: 'success',
                                            confirmButtonText: 'OK'
                                        }).then((result) => {
                                            if (result.value) {
                                                window.location.reload()
                                            }
                                        })

                                    }
                            })
                            .catch(error => {
                                    //Close Loading Modal
                                    Swal.close();
                                    //Show Error
                                    Swal.fire({
                                        title: 'Ocorreu um erro!',
                                        text: error.response,
                                        icon: 'error',
                                        confirmButtonText: 'OK'
                                    })
                            })

                        },
                        allowOutsideClick: () => !Swal.isLoading()
                    })
            }else{
                Swal.fire(
                    'Categoria Não Criada!',
                    'Por favor, preencha todos os campos!',
                    'error'
                )
            }
    })
}







const CategoryTable_ = new CategoryTable()


function EditCategory(category){
    Swal.fire({
        title: 'Editar Categoria',
        html: '<input id="swal-input1" value="'+category.desname+'" placeholder="Nome da Categoria" class="swal2-input">' +
        '<textarea id="swal-input2" placeholder="Descrição da Categoria"  class="swal2-input">'+category.desdescription+'</textarea>',
        focusConfirm: false,
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Atualizar Categoria',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        showCloseButton: true,
        closeButtonText: '<i class="fa fa-thumbs-down"></i> closear',
        closeButtonColor: '#d33',
        preConfirm: () => {
            return [
                document.getElementById('swal-input1').value,
                document.getElementById('swal-input2').value
            ]
        }
    }).then((result) => {
        //decode result
        const [name,description] = result.value

        if(name && description){

            const data = new FormData();

            data.append("desname", name);
            data.append("desdescription", description);
            data.append("idcategory", category.id);

            Swal.fire({
                title: 'Autenticando...',
                icon: 'warning',
                html: 'Aguarde enquanto validamos os dados...',
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                    //Axios Call
                    axios({
                        method: 'post',
                        url:  process.env.REACT_APP_URL + '/api/category/update',
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Accept': 'application/json'
                        },
                        data : data
                    })
                    .then(function (response) {
                            if(response.data){
                                Swal.close()
                                Swal.fire({
                                    title: 'Atualizado com Sucesso!',
                                    text: "Dados atualizados com sucesso!",
                                    icon: 'success',
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        window.location.reload()
                                    }
                                })
                            }
                        })
                        .catch(error => {
                                //Close Loading Modal
                                Swal.close();
                                //Show Error
                                Swal.fire({
                                    title: 'Ocorreu um erro!',
                                    text: error.response,
                                    icon: 'error',
                                    confirmButtonText: 'OK'
                                })
                        })
                },
                allowOutsideClick: () => !Swal.isLoading()
            })
        }else{
            Swal.fire(
                'Categoria Não Atualizada!',
                'Por favor, preencha todos os campos!',
                'error'
            )
        }
    }).catch(error => {
       
    })
}


function DeleteCategory(category){
    Swal.fire({
        title: 'Você tem certeza de que deseja remover esta categoria?',
        text: "A categoria não aparecerá mais na lista de categorias!",
        focusConfirm: false,
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Remover Categoria!',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        showCancelButton: true,
        cancelButtonText: '<i class="fa fa-thumbs-down"></i> Cancelar',
        cancelButtonColor: '#d33',
        }).then((result) => {

            if (result.value) {
                const data = new FormData();
                data.append("id", category.id);
                Swal.fire({
                    title: 'Autenticando...',
                    icon: 'warning',
                    html: 'Aguarde enquanto validamos os dados...',
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: false,
                    didOpen: () => {
                        Swal.showLoading()
                        //Axios Call
                        axios({
                            method: 'post',
                            url: process.env.REACT_APP_URL + '/api/category/delete',
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Accept': 'application/json'
                            },
                            data : data
                        })
                        .then(function (response) {
                                if(response.data){
                                    Swal.close()
                                    Swal.fire({
                                        title: 'Atualizado com Sucesso!',
                                        text: "Dados atualizados com sucesso!",
                                        icon: 'success',
                                        confirmButtonText: 'OK'
                                    }).then((result) => {
                                        if (result.value) {
                                            window.location.reload()
                                        }
                                    })
                                }
                        })
                        .catch(error => {
                                //Close Loading Modal
                                Swal.close();
                                //Show Error
                                Swal.fire({
                                    title: 'Ocorreu um erro!',
                                    text: error.response,
                                    icon: 'error',
                                    confirmButtonText: 'OK'
                                })
                        })
                    },
                    allowOutsideClick: () => !Swal.isLoading()
                })
            }
    })
}


const CategoryList = () => { 

    const [categories,setCategories] = useState([])


    useEffect( () =>{
        CategoryTable_.get().then(res => {
            //set only if isactive is 1
            setCategories(res.filter(category => category.isactive === 1))
      })
    }, []);
        //export function EditCategory to be used in the table
        window.EditCategory = EditCategory;
        window.DeleteCategory = DeleteCategory;
        
        //if table already loaded then destroy it
        if ($.fn.DataTable.isDataTable("#table")) {
            $("#table").DataTable().destroy();
        }


        if (categories.length > 0) {
            console.log({
                data: categories
            })
            const table = $("#table").DataTable({
                data: categories,
                columns: [
                    { data: "id" },
                    { data: "desname" },
                    { data: "desdescription" },
                    { data: "dtregister" },
                ],
                //style last column
                columnDefs: [
                    {
                        targets: 4,
                        render: function (data, type, row) {
                            let frow = JSON.stringify(row).replace(/"/g, "'");
                            //button with row object onclick
                            return `<button class="btn-sm btn-primary" onclick="EditCategory(${frow})"><i class="lar ri-pencil-line"></i></button>
                                    <button class="btn-sm btn-danger"  onclick="DeleteCategory(${frow})"><i class="ri-delete-bin-2-line"></i></button>`;
                        }
                    },
                ],
                //hide "shoe entries button"
                lengthChange: false,
                //change position of "search" input
                dom: 'lrtip',
            });
            // #column3_search is a <input type="text"> element
            $('#input-search-data').on( 'keyup', function () {
                //saerch from table by name in all columns
                table.search( this.value ).draw();
            } );
    }
       

    return (
            <> 
                <Container fluid>
                    <Card>
                        <Card.Header className="d-flex justify-content-between no-bottom-border" >
                            <Card.Header.Title>
                                <h4 className="card-title">Lista de Categorias</h4>
                            </Card.Header.Title>
                            <div className="iq-card-header-toolbar d-flex align-items-center">
                                <Button onClick={AddNewCategory} className="btn btn-primary">Adicionar Categoria</Button>
                            </div>
                        </Card.Header>
                        <div className="datatable-input-holder">
                            <input type="text" className="form-control datatable-search" id="input-search-data" placeholder="Pesquisar" />
                        </div>
                        <Card.Body>
                        <div class="table-responsive p-0 pb-2">
                            <table id="table" className="table align-items-center justify-content-center mb-0">
                                <thead>
                                    <tr>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">ID</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Título</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Descrição</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Registro</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody></tbody>
                            </table>
                        </div>
                        </Card.Body>
                    </Card>
                </Container>
            </>
    )
}
export default CategoryList;