import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

function DropdownBtn({categories, currentName, size}) {
  return (
    <>
      {['Danger'].map(
        (variant) => (
          <DropdownButton
            className="CustomBTN"
            as={ButtonGroup}
            key={variant}
            id={`dropdown-variants-${variant}`}
            variant="danger"
            size={size}
            title={currentName}
           >
            {categories.map((category) => (
              <Dropdown.Item key={category.id} href={category.id != 0 ? `/courses/${category.id}` : `/courses` }  eventKey="1">{category.desname}</Dropdown.Item>
            ))}
          </DropdownButton>
        ),
      )}
    </>
  );
}

export default DropdownBtn;