import axios from 'axios';


export default class User {
  

    //criar um constructor que irá receber parametros como página atual, registros por página, 
    //total de registros, total de páginas, etc.
    constructor(page, perPage) {
        //url from API
        this.root = process.env.REACT_APP_URL;

        //Página Atual
        this.page = page;
        //Resultados por Página
        this.perPage = perPage;
    }




   /**
    * @description Essa função irá retornar os dados do usuário
    * @returns {Promise}
    */
    async get() {
        return new Promise((resolve, reject) => {
            axios.get(`${this.root}/api/user/fetch`, {
                params: {
                    page: 1,
                    limit: 2
                }
            })
            .then(res => {
                    resolve(res.data);
            })
            .catch(err => {
                    reject(err);
            })
        })
    }
        


}