import React from 'react'
import { Container, Row, Col,Breadcrumb } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import mailpic from '../../../assets/images/email.png'
import phonepic from '../../../assets/images/phone.png'
//img
import aboutstars from '../../../assets/images/Estrelas.png'
import contactimage from '../../../assets/images/Contato.png'
const AboutUs= () => {
   return (
      <>
        <main id="main" className="site-about">
            <Container fluid className="p-0" >
               <div className="map-container-about-us" style={{ left: "0px"}}>
                  <Container>
                     <Row className="about-us-row text-center align-items-center">
                        <Col md="8">
                           <div className=" text-md-left iq-title-box iq-title-default">
                              <h2 className="iq-title font-weight-bold">
                                Contato  <img src={aboutstars} alt="aboutstars" className="bgstars " />
                              </h2>                        
                              <p className="iq-title-desc dmobile pt-4" style={{ color:"white" }}>Precisa de ajuda? Entre em contato conosco:</p>    
                              <p className="iq-title-desc smobile pt-4" style={{ color:"white" }}>Precisa de ajuda? <br/>Entre em contato conosco:</p>                         
                              <p className="iq-title-desc  info-text  pt-4" style={{ color:"white" }}><img src={phonepic} className="logopic"/>Tel.: (954) 798-3510</p>                     
                              <p className="iq-title-desc  info-text  pt-3 pb-5" style={{ color:"white" }}><img src={mailpic} className="logopic"/>E-mail: marketing@adreamclass.com</p>                     
                              <br/>
                              <div className='dmobile'>
                                 <p style={{ color:"white" }}>Política de Privacidade</p>
                                 <p className='text-sublimed pt-2'>Privacidade</p>
                              </div>
                           </div>
                        </Col>
                        <Col md="4">
                            <img src={contactimage} alt="contactimage" className="contactImage" />
                            <div className='smobile'>
                              <p className='pt-5' style={{ color:"white" }}>Política de Privacidade</p>
                              <p>Privacidade</p>
                            </div>
                        </Col>
                       
                     </Row>
                  </Container>               
               </div>
            </Container>
      </main>
      </>
   )
}
export default AboutUs;