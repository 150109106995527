import jwt from '../../../../router/auth/JsonWebToken'
import axios from 'axios'

export default function Logout(){

    const Token = localStorage.getItem("auth")
   
    let _jwt = new jwt(Token);
    let isLogged = _jwt.validateToken();

    if(isLogged){
        let data = new FormData();
        data.append('token', Token);
  
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + '/api/auth/logout',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            data : data
        })
        .then(function (response) {
            //remove from localStorage
            localStorage.removeItem('auth')
            //redirect to login
            window.location.href = '/auth/login'
         })
        .catch(error => {
            window.location.href = '/'
        })
    }else{
        window.location.href = '/'
    }

}