import React,{useState} from 'react'
import { Container, Row, Col, Table, Breadcrumb } from 'react-bootstrap'
import aboutus1 from '../../../assets/images/about-us/bg.png'
import {Link} from 'react-router-dom'


import DreamClassPayments from '../../../controller/DreamClassPayments'
import Swal from 'sweetalert2'

import JsonWebToken from '../../../router/auth/JsonWebToken'

const Pricing = () => { 
    const [pricing, setPricing]=useState('pricing') 
    const pricingLists = [
    {
        title: 'Acesso a Todos os Cursos',
        icon:  'fa fa-times-circle',
        icon2: 'fa fa-check-circle'
    },
    {
        title: 'Acesso a Todos os Episódios',
        icon: 'fa fa-times-circle',
        icon2: 'fa fa-check-circle'
    },
    {
        title: 'Suporte 24/7 Disponível',
        icon: 'fa fa-times-circle', 
        icon2: 'fa fa-check-circle'
    },
    {
       title: 'Conexão Rápida e Estável',
       icon: 'fa fa-times-circle',
        icon2: 'fa fa-check-circle'
    },
    {
       title: 'Vídeos em Até 1080p',
       icon:  'fa fa-times-circle',
       icon2: 'fa fa-check-circle'
    }
]


//get .env url 
const url = process.env.REACT_APP_URL

const publicKey = process.env.REACT_APP_PUBLIC_TOKEN


    /**
 * @author Vitor Hugo
 * @param {*} e 
 * @description This function will try to initiate transaction
 * @returns {void}
 * @constructor 
 */
const TryToSaveBasicData = (e) => {
    
    e.preventDefault();
     //Save into Variables
    let privateKey = 'TEST-1276450813396396-060207-be6cf3f3a9f226a711361067e92ac30a-1135190200';
    const _DreamClassPayment = new DreamClassPayments(publicKey, privateKey, url);

    let _token = new JsonWebToken(localStorage.getItem("auth"));
    let userID = _token.retrieveData(true);
   
     try{
            /**
             * @example Call This to Async Function
             * @description This function will try to initiate transaction, creating the user.
             * @returns {void}
             */
             Swal.fire({
                title: 'Processando...',
                icon: 'warning',
                html: 'Aguarde enquanto validamos os dados...',
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                    //Axios Call
                    _DreamClassPayment.CreateNewSignature({
                        userID: userID.id,
                    })
                    .then(function (data) {
                        Swal.fire({
                            title: `Processado com Sucesso!`,
                            icon: 'info',
                            html: `Para prosseguir clique em "Avançar"`,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            showConfirmButton: true,
                            showCloseButton: true,
                            confirmButtonText: 'Avançar',
                            closeButtonText: 'Fechar',
                        }).then((modal) => {
                            if (modal.value) {
                                //jsoSON Decode data
                                let data_ = JSON.parse(data);
                                //open new window
                                window.open(JSON.parse(data_.data).init_point, '_blank');
                                // var left = (window.screen.width - 650) / 2;
                                // var top = (window.screen.height - 650) / 4;
                                //  window.open(JSON.parse(data_.data).init_point, "Assinatura", 'resizable=yes, width=' + 650  + ', height=' + 650 + ', top=' + top + ', left=' + left + ' ,toolbar=1,scrollbars= 1,location=0,statusbar= 0 ,menubar=1 ,resizable=1');
                            }
                        })
                     })
                    .catch(error => {
                        Swal.fire({
                            title: `Ocorreu um Erro (${error ? error.code : ''})`,
                            icon: 'error',
                            html: `Ocorreu um erro ao tentar iniciar a transação - ${error ?JSON.parse(error.response.data.data).message : ''}`,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            showConfirmButton: false,
                            showCloseButton: true,
                            closeButtonText: 'Fechar',
                            timer: 2000
                        })
                    })
    
                },
                allowOutsideClick: () => !Swal.isLoading()
            })
        } catch (error) {
            console.log(error)
            //swal error
            Swal.fire({
                title: 'Ocorreu um Erro',
                icon: 'error',
                html: 'Ocorreu um erro ao tentar iniciar a transação, tente novamente mais tarde.',
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                showCloseButton: true,
                closeButtonText: 'Fechar',
                timer: 2000
            })
        }
}


    return (
        <>
            <div className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50" style={{backgroundImage: `url(${aboutus1})`}}>  
                <Container fluid> 
                    <Row className="align-items-center">
                        <Col sm="12">
                            <nav aria-label="breadcrumb" className="text-center iq-breadcrumb-two">
                                <h2 className="title">Adquirir Plano de Assinatura</h2>
                                <Breadcrumb className="main-bg">
                                    <Breadcrumb.Item>Início</Breadcrumb.Item> 
                                    <Breadcrumb.Item active>Planos de Assinatura</Breadcrumb.Item>
                                </Breadcrumb>
                            </nav>
                        </Col>
                    </Row> 
                </Container>
            </div>
            <main id="main" className="site-main">
                <Container>
                    <Row>
                        <Col lg="12" sm="12">
                            <div className="iq-pricing-card">
                                <div className="table-responsive iq-pricing pt-2">
                                    <Table id="my-table" className="table" data-active="premium">
                                        <thead>
                                            <tr>
                                                <th className="text-center iq-price-head"></th>
                                                <th className="text-center iq-price-head free">
                                                    <div className={`iq-price-box ${pricing === 'Basic' ? 'active' : ''} `} onClick={() =>setPricing('Basic')}>
                                                        <h3 className="iq-price-rate text-white">R$0,00<small> / Mês</small></h3>
                                                        <span className="type">Grátis</span>
                                                    </div>
                                                </th>
                                                <th className="text-center iq-price-head premium">
                                                    <div className={`iq-price-box  ${pricing === 'pricing' ? 'active' : ''}`} onClick={() =>setPricing('pricing')}>
                                                        <h3 className="iq-price-rate text-white" >R$39,00<small> / Mês</small></h3>
                                                        <span className="type">Super</span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody> 
                                            {pricingLists.map((item) => (               
                                                <tr key={item.title}>
                                                    <th className="text-center" scope="row"> {item.title}</th>
                                                    <td className={`text-center iq-child-cell ${pricing === 'Basic' ? 'active' : ''}`}>
                                                        <i className={`${item.icon}`}></i>
                                                    </td>
                                                    <td className={`text-center iq-child-cell ${pricing === 'Platinum' ? 'active' : ''}`}>
                                                        <i className={`${item.icon2}`}></i>
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <th className="text-center iq-price-footer"></th>
                                                <td className="text-center iq-price-footer">
                                                    <div className="align-items-center r-mb-23" data-animation-in="fadeInUp" data-delay-in="1.3">
                                                    <Link to="#" className="btn btn-hover iq-button">Grátis</Link>
                                                    </div>
                                                </td>
                                                <td className="text-center iq-price-footer active">
                                                    <div className="align-items-center r-mb-23" data-animation-in="fadeInUp" data-delay-in="1.3">
                                                    <Link onClick={TryToSaveBasicData} className="btn btn-hover iq-button">Assinar Agora</Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>
                    </Row>   
                </Container>
            </main>
        </>
    )
}
export default Pricing;