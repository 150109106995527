import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Card from '../../../components/Card'
import Swal from 'sweetalert2'
//img
import user01 from '../../../assets/images/user/01.jpg'
import user05 from '../../../assets/images/user/05.jpg'
import user03 from '../../../assets/images/user/03.jpg'
import user08 from '../../../assets/images/user/08.jpg'
import user06 from '../../../assets/images/user/06.jpg'
import user10 from '../../../assets/images/user/10.jpg'

import SeriesTable from "../../../database/table/series";
import Attachments from "../../../database/table/attachments";
import axios from 'axios'

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import moment from 'moment'
const Attachments_ = new Attachments()
const SeriesTable_ = new SeriesTable()

function Redirect(Serie){
    window.location.href = Serie
}

async function  AddNewAttachment(idserie){
    const { value: formValues } = await Swal.fire({
        title: 'Dados do Anexo',
        html:
          '<input id="swal-input1" placeholder="Nome do Arquivo" required class="swal2-input">' +
          '<input id="swal-input2" placeholder="Descrição do Arquivo" required class="swal2-input">' +
          '<input id="swal-input3" type="file" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps,video/*" required aria-label="Envie um arquivo" class="custom-input-file swal2-input">', 
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonText: 'Adicionar',
        cancelButtonText: "Cancelar",
        preConfirm: () => {
          return [
            document.getElementById('swal-input1').value,
            document.getElementById('swal-input2').value,
            document.getElementById('swal-input3').files[0]
          ]
        }
      })
      if (formValues) {
            //check if the fields are empty
            if(formValues[0] == "" || formValues[1] == "" || formValues[2] == ""){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Preencha todos os campos!',
                })
            }else{
                    //create form data to send to the server
                    const data = new FormData();
                    data.append('idserie', idserie);
                    data.append('desname', formValues[0]);
                    data.append('desdescription', formValues[1]);
                    data.append('files', formValues[2]);
                     //swal with loading screen
                    Swal.fire({
                            title: 'Cadastrando...',
                            icon: 'warning',
                            html: 'Aguarde enquanto validamos os dados...',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            showConfirmButton: false,
                            didOpen: () => {
                                Swal.showLoading()
                                //Axios Call
                                axios({
                                    method: 'post',
                                    url: process.env.REACT_APP_URL + '/api/attachments/create',
                                    headers: {
                                        'Content-Type': 'multipart/form-data',
                                        'Accept': 'application/json'
                                    },
                                    data : data
                                })
                                .then(function (response) {
                                        if(response){
                                            Swal.fire({
                                                title: 'Sucesso!',
                                                icon: 'success',
                                                html: 'Série atualizada com sucesso!',
                                                allowOutsideClick: false,
                                                allowEscapeKey: false,
                                                allowEnterKey: false,
                                                showConfirmButton: false,
                                                timer: 2000,
                                            }).then((timer) => {
                                                //update page 
                                                window.location.reload()
                                            }
                                        )}
                                })
                                .catch(function ({response}) {
                                    Swal.fire({
                                        title: 'Erro!',
                                        icon: 'error',
                                        html: response.data.message ,
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        allowEnterKey: false,
                                        showConfirmButton: false,
                                        timer: 2000
                                    })
                                });
                            }
                    })
            }
      }
}

function RemoveEpisode(Episode){

    Swal.fire({
        title: 'Você tem certeza de que deseja remover este anexo?',
        text: "Você não poderá reverter isso! Este anexo será completamente removido do servidor!",
        focusConfirm: false,
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Remover Anexo!',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        showCancelButton: true,
        cancelButtonText: '<i class="fa fa-thumbs-down"></i> Cancelar',
        cancelButtonColor: '#d33',
        }).then((result) => {

            if (result.value) {
                const data = new FormData();
                data.append("id", Episode.id);
                data.append("desfile", Episode.desfile);
                Swal.fire({
                    title: 'Autenticando...',
                    icon: 'warning',
                    html: 'Aguarde enquanto validamos os dados...',
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: false,
                    didOpen: () => {
                        Swal.showLoading()
                        //Axios Call
                        axios({
                            method: 'post',
                            url: process.env.REACT_APP_URL + '/api/attachments/delete',
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Accept': 'application/json'
                            },
                            data : data
                        })
                        .then(function (response) {
                                if(response.data){
                                    Swal.close()
                                    Swal.fire({
                                        title: 'Atualizado com Sucesso!',
                                        text: "Dados atualizados com sucesso!",
                                        icon: 'success',
                                        confirmButtonText: 'OK'
                                    }).then((result) => {
                                        if (result.value) {
                                            window.location.reload()
                                        }
                                    })

                                }
                        })
                        .catch(error => {
                                //Close Loading Modal
                                Swal.close();
                                //Show Error
                                Swal.fire({
                                    title: 'Ocorreu um erro!',
                                    text: error.response,
                                    icon: 'error',
                                    confirmButtonText: 'OK'
                                })
                        })

                    },
                    allowOutsideClick: () => !Swal.isLoading()
                })
            }
    })
}

const User = ({match}) => {

    const [Episodes, setEpisodes] = useState([])
    const [currentSerie, setCurrentSerie] = useState([]);

    useEffect(() => {
    
        SeriesTable_.get(match.params.id).then((data) => {
            setCurrentSerie(data)
        })

        Attachments_.get(match.params.id).then((res) => {
            //foreach res.desactors, json.parse and convert to string
            res.map((item) => {
                //json decode item.desactors and convert desactor.label to string separated with ","
            });
           setEpisodes(res);
         })

    }, []);

    

    window.RemoveEpisode = RemoveEpisode
    window.Redirect = Redirect  
     //if table already loaded then destroy it
     if ($.fn.DataTable.isDataTable("#table-movie")) {
         $("#table-movie").DataTable().destroy();
     }

     if (Episodes.length > 0) {
         console.log({
             data: Episodes
         })
         const table = $("#table-movie").DataTable({
             data: Episodes,
             columns: [
                 { data: "id" },
                 { data: "desname" },
                 { data: "desdescription" },
                 { data: "desfile" },
                 { data: "createdAt" },
                 { data: "desname" },
             ],
             //style last column
             columnDefs: [
                {
                    targets: 1,
                    render: function (data, type, row) {
                       //se a descricao for maior que 50 caracteres, entao corta e adiciona ...
                       if (data.length > 20) {
                           return data.substr(0, 20) + "...";
                       }
                       return data;
                    }
                },
                {
                     targets: 2,
                     render: function (data, type, row) {
                        //se a descricao for maior que 50 caracteres, entao corta e adiciona ...
                        if (data.length > 20) {
                            return data.substr(0, 20) + "...";
                        }
                        return data;
                     }
                 },
                 {
                    targets: 3,
                    render: function (data, type, row) {
                       //se a descricao for maior que 50 caracteres, entao corta e adiciona ...
                       return "<a href='"+process.env.REACT_APP_URL+"/api/view/attachment/"+data+"' target='_blank'>"+data+"</a>";
                    }
                 },
                 {
                    targets: 4,
                    render: function (data, type, row) {
                       //se a descricao for maior que 50 caracteres, entao corta e adiciona ...
                       return moment(data).format('DD/MM/YYYY HH:mm:ss');
                    }
                 },
                 {
                    targets: 5,
                    render: function (data, type, row) {
                        let frow = JSON.stringify(row).replace(/"/g, "'");
                        //button with row object onclick
                        return `<button class="btn-sm btn-primary"  onclick="Redirect('/manager/edit-attach/${row.id}')"><i class="lar ri-pencil-line"></i></button>
                                <button class="btn-sm btn-danger"   onclick="RemoveEpisode(${frow})"><i class="ri-delete-bin-2-line"></i></button>`;
                    }
                },
             ],
             //hide "shoe entries button"
             lengthChange: false,
             //change position of "search" input
             dom: 'lrtip',
         });
         // #column3_search is a <input type="text"> element
         $('#input-search-data').on( 'keyup', function () {
             //saerch from table by name in all columns
             table.search( this.value ).draw();
         } );
 }
    



    return (
        <>
            <Container fluid>
                    <Card>
                        <Card.Header className="d-flex justify-content-between no-bottom-border" >
                            <Card.Header.Title>
                                <h4 className="card-title">Anexos do Curso: {currentSerie.desname}</h4>
                            </Card.Header.Title>
                            <div className="iq-card-header-toolbar d-flex align-items-center">
                                <button onClick={() => AddNewAttachment(match.params.id) } className="btn btn-primary">Adicionar Anexo</button>
                            </div>
                        </Card.Header>
                        <div className="datatable-input-holder">
                            <input type="text" className="form-control datatable-search" id="input-search-data" placeholder="Pesquisar" />
                        </div>
                        <Card.Body>
                        <div className="table-responsive p-0 pb-2">
                            <table id="table-movie" className="table align-items-center justify-content-center mb-0">
                                <thead>
                                    <tr>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">ID</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Nome</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Descrição</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Arquivo</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Data</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Ações</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody></tbody>
                            </table>
                        </div>
                        </Card.Body>
                    </Card>
            </Container>
        </>
    )
}
export default User;