import {Switch,Route,useLocation} from 'react-router-dom'
import {TransitionGroup,CSSTransition} from "react-transition-group";

//main
import Dashbord from '../views/dashboard/main/dashbord'
import Rating from '../views/dashboard/main/rating';
import Comment from '../views/dashboard/main/comment';
import User from '../views/dashboard/main/user';
import Pricing from '../views/dashboard/main/pricing';

//App
import UserProfile from '../views/dashboard/app/usermanagement/userprofile'
import UserPrivacySetting from '../views/dashboard/app/usermanagement/userprivacysetting'
import UserAccountSettingList from '../views/dashboard/app/usermanagement/useraccountsetting'
import UserProfileEdit from '../views/dashboard/app/usermanagement/userprofileedit'


//Category
import AddCategory from '../views/dashboard/category/add-category'
import CategoryList from '../views/dashboard/category/category-list';

import DirectorList from '../views/dashboard/actor/actors';
import SignatureList from '../views/dashboard/signature/list';

//Movie
import AddMovie from '../views/dashboard/movie/add-movie';
import EditMovie from '../views/dashboard/movie/edit-movie';
import MovieList from '../views/dashboard/movie/movie-list';

//Show
import AddShow from '../views/dashboard/show/add-show';
import EditShow from '../views/dashboard/show/edit-show';
import EditCourse from '../views/dashboard/show/edit-course';

import EditSerie from '../views/dashboard/show/edit-serie';
import ShowList from '../views/dashboard/show/show-list';
import ShowRelated from '../views/dashboard/show/show-related';


import ShowRelatedFiles from '../views/dashboard/show/show-related-files';
import EditFile from '../views/dashboard/movie/edit-file';

import FunctionaryProtectedRoutes from "./auth/FunctionaryProtectedRoutes";



const FunctionaryDashboard = () => {

    let location = useLocation();

    return (
        <TransitionGroup>
            <CSSTransition
                key={location.key}
                classNames="fade"
                timeout={300}
            >
                <Switch  location={location}>
                    <FunctionaryProtectedRoutes SecureLock path="/manager/dashboard" exact component={Dashbord} />

                    <FunctionaryProtectedRoutes SecureLock path="/manager/rating" exact component={Rating} />
                    <FunctionaryProtectedRoutes SecureLock path="/manager/comment" exact component={Comment} />
                    <FunctionaryProtectedRoutes SecureLock path="/manager/user" exact component={User} />
                    <FunctionaryProtectedRoutes SecureLock path="/manager/pages-pricing" exact component={Pricing} />

                    {/* App */}
                    <FunctionaryProtectedRoutes SecureLock path="/manager/user-profile"         component={UserProfile} />
                    <FunctionaryProtectedRoutes SecureLock path="/manager/user-privacy-setting" component={UserPrivacySetting} />
                    <FunctionaryProtectedRoutes SecureLock path="/manager/user-account-setting" component={UserAccountSettingList} />
                    <FunctionaryProtectedRoutes SecureLock path="/manager/user-profile-edit"    component={UserProfileEdit} />

                    {/* Category */}
                    <FunctionaryProtectedRoutes SecureLock path="/manager/add-category"       component={AddCategory}/>
                    <FunctionaryProtectedRoutes SecureLock path="/manager/category-list"      component={CategoryList}/>
                    <FunctionaryProtectedRoutes SecureLock path="/manager/actors-list"      component={DirectorList}/>

                    <FunctionaryProtectedRoutes SecureLock path="/manager/signature"      component={SignatureList}/>

                    {/* Movie */}
                    <FunctionaryProtectedRoutes SecureLock path="/manager/add-movie"        component={AddMovie}/>
                    <FunctionaryProtectedRoutes SecureLock path="/manager/edit-movie/:id"        component={EditMovie}/>
                    <FunctionaryProtectedRoutes SecureLock path="/manager/movie-list"       component={MovieList}/>

                    {/* Show */}
                    <FunctionaryProtectedRoutes SecureLock path="/manager/add-show"        component={AddShow}/>
                    <FunctionaryProtectedRoutes SecureLock path="/manager/add-serie/:id"       component={EditShow}/>
                    <FunctionaryProtectedRoutes SecureLock path="/manager/edit-course/:id"       component={EditCourse}/>

                    <FunctionaryProtectedRoutes SecureLock path="/manager/edit-serie/:id"       component={EditSerie}/>

                    <FunctionaryProtectedRoutes SecureLock path="/manager/show-list"       component={ShowList}/>
                    <FunctionaryProtectedRoutes SecureLock path="/manager/list-related/:id"       component={ShowRelated}/>
                    <FunctionaryProtectedRoutes SecureLock path="/manager/list-files/:id"       component={ShowRelatedFiles}/>
                    <FunctionaryProtectedRoutes SecureLock path="/manager/edit-attach/:id"        component={EditFile}/>
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    )
}

export default FunctionaryDashboard