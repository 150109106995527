import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import { Col, Row, Container, Form, Button } from 'react-bootstrap'
// import Choices from 'react-choices'

// Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//select
import Select from 'react-select';

//Global Libs
import Swal from 'sweetalert2'
import axios from 'axios'


import user from '../../../../assets/images/user/user.jpg'


import JsonWebToken from '../../../../router/auth/JsonWebToken';

let _jwt = new JsonWebToken(localStorage.getItem("auth"));




const TryToSaveBasicData = (e) => {

    //hande submit
    e.preventDefault();
    //Save into Variables]
    let data = new FormData();
    data.append("id", _jwt.getId());
    data.append('desname',  e.target.desname.value );
    data.append('dtbirth',  e.target.dtbirth.value );
    data.append('desdescription',  e.target.desdescription.value );
    //=============================================================
    let file = document.getElementById("avatar").files[0];
    if(file){
        data.append('avatar', file);
    }
    //=============================================================
    
    //swal with loading screen
    Swal.fire({
            title: 'Autenticando...',
            icon: 'warning',
            html: 'Aguarde enquanto validamos os dados...',
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
                //Axios Call
                axios({
                    method: 'post',
                    url: process.env.REACT_APP_URL + '/api/user/update',
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Accept': 'application/json'
                    },
                    data : data
                })
                .then(function (response) {
                        if(response.data){
                            Swal.close()
                            Swal.fire({
                                title: 'Atualizado com Sucesso!',
                                text: "Dados atualizados com sucesso!",
                                icon: 'success',
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    window.location.href = "/setting";
                                }
                            })

                        }
                 })
                .catch(error => {
                        //Close Loading Modal
                        Swal.close();
                        //Show Error
                        Swal.fire({
                            title: 'Ocorreu um erro!',
                            text: error.response,
                            icon: 'error',
                            confirmButtonText: 'OK'
                        })
                })

            },
            allowOutsideClick: () => !Swal.isLoading()
        })
}




const UserProfile = () => {

     let isLogged = _jwt.validateToken();
            _jwt.retrieveAllData(isLogged).then(_data => {
                Swal.close()
                document.getElementById("desname").value = _data.data[0].desname;
                document.getElementById("desdescription").value = _data.data[0].desdescription;
                document.getElementById("dtbirth").value = _data.data[0].dtbirth;
                document.getElementById("profilepic").src = process.env.REACT_APP_URL +  _data.data[0].desprofile;
            })

    //when a file is uploaded in #file input run this function
    const handleFileUpload = (e) => {
        //get file
        //when the file is uploaded change #profilepic img src to the file
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onloadend = () => {
            document.getElementById("profilepic").src = reader.result;
        }
        if (file) {
            reader.readAsDataURL(file);
        }
        else {
            document.getElementById("profilepic").src = user;
        }

    }







    return (
        <>
            <section className="m-profile manage-p">
                <Container>
                    <Row className="row align-items-center justify-content-center h-100">
                        <Col lg="10">
                            <div className="sign-user_card">
                                <Row>
                                    <Col lg="2">
                                        <div className="upload_profile d-inline-block" onClick={() => {document.getElementById("avatar").click()}}>
                                         
                                            <img src={user} id="profilepic" className="profile-pic avatar-130 rounded-circle img-fluid " alt="user"/>
                                            
                                            <div className="p-image"  >
                                                <i className="ri-pencil-line upload-button"></i>
                                                <input form="basicdata" onChange={handleFileUpload} name="avatar" id="avatar" className="file-upload" type="file" accept="image/*"/>
                                            </div>

                                        </div>
                                      </Col>
                                    <Col lg="10" className="device-margin">

                                        <div className="profile-from">
                                            <h4 className="mb-3">Alterar Informações </h4>
                                           
                                            <Form id="basicdata" className="mt-4" onSubmit={TryToSaveBasicData}>

                                                <Form.Group className="form-group">
                                                    <Form.Label>Seu Nome</Form.Label>
                                                    <Form.Control type="text" className="form-control mb-0" id="desname" name="desname" placeholder="Insira o seu Nome" autoComplete="off" required/>
                                                </Form.Group>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Descrição (Sobre você)</Form.Label>
                                                    <Form.Control type="text" className="form-control mb-0" id="desdescription" name="desdescription" placeholder="[....]" autoComplete="off" required/>
                                                </Form.Group>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Data de Aniversário (Y/m/d)</Form.Label>
                                                    <input type="date"   id="dtbirth" name="dtbirth" />
                                                </Form.Group>
                                                <div className="d-flex">
                                                    <Button type="submit" className="btn btn-hover">Salvar Alterações</Button>
                                                </div>

                                            </Form>
                                        </div>


                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>          
            </section>
        </>
    )
}

export default UserProfile