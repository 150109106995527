import React, { useEffect, useState } from "react";
import { gsap } from "gsap";
import { Link } from "react-router-dom";
import { Container, Col, Row, Nav, Tab } from "react-bootstrap";
import FsLightbox from "fslightbox-react";
import Select from "react-select";

// img
import dark from "../../../assets/images/dark.png";
import stars from "../../../assets/images/stars.png";
import food from "../../../assets/images/food.png";
import logo from "../../../assets/images/logo.png";
import icon from "../../../assets/video/trailer.mp4";

// favorite img
import fav1 from "../../../assets/images/favorite/01.jpg";
import fav2 from "../../../assets/images/favorite/02.jpg";
import fav3 from "../../../assets/images/favorite/03.jpg";
import fav4 from "../../../assets/images/favorite/04.png";
import fav5 from "../../../assets/images/favorite/05.jpg";

// upcoming img
import upcoming1 from "../../../assets/images/upcoming/01.jpg";
import upcoming2 from "../../../assets/images/upcoming/02.jpg";
import upcoming3 from "../../../assets/images/upcoming/03.jpg";
import upcoming4 from "../../../assets/images/upcoming/04.jpg";
import upcoming5 from "../../../assets/images/upcoming/05.jpg";

// suggested
import suggested1 from "../../../assets/images/suggested/01.jpg";
import suggested2 from "../../../assets/images/suggested/02.jpg";
import suggested3 from "../../../assets/images/suggested/03.jpg";
import suggested4 from "../../../assets/images/suggested/04.jpg";
import suggested5 from "../../../assets/images/suggested/05.jpg";

// parallax
import parallax3 from "../../../assets/images/parallax/p1.jpg";
import parallax4 from "../../../assets/images/parallax/parallax-logo.png";

// trending
import trending1 from "../../../assets/images/trending/01.jpg";
import trending2 from "../../../assets/images/trending/02.jpg";
import trending3 from "../../../assets/images/trending/03.jpg";
import trending4 from "../../../assets/images/trending/04.jpg";
import trending5 from "../../../assets/images/trending/05.jpg";
import trending6 from "../../../assets/images/trending/06.jpg";
import trendinglabel from "../../../assets/images/trending/trending-label.png";

// episodes
import episodes1 from "../../../assets/images/episodes/01.jpg";
import episodes2 from "../../../assets/images/episodes/02.jpg";
import episodes3 from "../../../assets/images/episodes/03.jpg";
import episodes4 from "../../../assets/images/episodes/04.jpg";
import episodes5 from "../../../assets/images/episodes/05.jpg";

// tvthrillers
import tvthrillers1 from "../../../assets/images/tvthrillers/01.jpg";
import tvthrillers2 from "../../../assets/images/tvthrillers/02.jpg";
import tvthrillers3 from "../../../assets/images/tvthrillers/03.jpg";
import tvthrillers4 from "../../../assets/images/tvthrillers/04.jpg";
import tvthrillers5 from "../../../assets/images/tvthrillers/05.jpg";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from "swiper";
import "swiper/swiper-bundle.css";

import SerieTable from "../../../database/table/series";
import CategoryTable from "../../../database/table/category";

import DropdownBtn from "../../../components/DropdownBtn"
// import Accordion from "../../../components/Accordion"

SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

const TableSeries = new SerieTable();

const gsapAnimate = {
	getData: (elem) => {
		const option = {
			opacity: 0,
			scale: 1,
			position: {
				x: 0,
				y: 0,
			},
			ease: "",
			duration: 1,
			delay: 0.4,
			rotate: 0,
		};
		if (elem !== undefined) {
			option.position.x = gsapAnimate.validValue(
				elem.dataset.iqPositionX,
				0
			);

			option.position.y = gsapAnimate.validValue(
				elem.dataset.iqPositionY,
				0
			);

			option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0);

			option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1);

			option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0);

			option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, 0.4);

			option.duration = gsapAnimate.validValue(
				elem.dataset.iqDuration,
				1.5
			);

			option.ease = gsapAnimate.validValue(elem.dataset.iqEase, "");

			const setOption = {
				opacity: option.opacity,
				scale: option.scale,
				x: option.position.x,
				y: option.position.y,
				ease: option.ease,
				rotate: option.rotate,
				duration: option.duration,
				delay: option.delay,
			};

			return setOption;
		} else {
			return { opacity: 0 };
		}
	},
	onStart: (elem) => {
		const setOption = gsapAnimate.getData(elem);

		gsap.from(elem, setOption);
	},

	onEnd: (elem) => {
		const setOption = gsapAnimate.getData(elem);

		gsap.to(elem, setOption);
	},

	onStartEnd: (elem) => {
		const setOption = gsapAnimate.getData(elem);

		const setEndOption = gsapAnimate.getData(elem);

		setEndOption.opacity = 1;

		setEndOption.x = 0;

		setEndOption.y = 0;

		setEndOption.rotate = 0;

		setEndOption.scale = 1;

		gsap.fromTo(elem, setOption, setEndOption);
	},
	validValue: (attr, defaultVal) => {
		if (attr !== undefined && attr !== null) {
			return Number(attr);
		}
		return Number(defaultVal);
	},
};

const Homepage = ({ match }) => {
	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	const [toggler1, setToggler1] = useState(false);
	const [toggler2, setToggler2] = useState(false);
	const [toggler3, setToggler3] = useState(false);

	const animationInit = () => {
		if (
			document.querySelector(".swiper-container .swiper-slide-active") !==
			null
		) {
			const gsapElem = document
				.querySelector(".swiper-container .swiper-slide-active")
				.querySelectorAll('[data-iq-gsap="onStart"]');

			Array.from(gsapElem, (elem) => {
				return gsapAnimate.onStartEnd(elem);
			});
		}
	};

	const [cats, setCat] = useState([]);
	const cat = new CategoryTable();

	useEffect(() => {
		cat.get().then((res) => {
			//push category "todos" to the first position
			res.unshift({ id: 0, desname: "Todos", desdescription: "Todos", isactive: 1 });

			setCat(res.filter(category => category.isactive === 1));
		});
	}, []);

	let currentCat;

	if (match.params.category == undefined) {
		currentCat = "Categorias";
	} else {
		cats.forEach((cat) => {
			if (cat.id == match.params.category) {
				currentCat = cat.desname;
			}
		});
	}

	function openNav() {
		document.getElementById("myNav").style.height = "100%";
	}

	function closeNav() {
		document.getElementById("myNav").style.height = "0%";
	}

	const [movies, setMovies] = useState([]);
	const [filmeFixado, setFilmeFixado] = useState([]);
	useEffect(() => {
		TableSeries.get_category(match.params.category).then((res) => {

			if (match.params.category == undefined) {
				let temDestaque = 0;
				res.forEach((movie) => {
					//verifica se pode ficar em destaque
					if (movie.descapa == 1) {
						temDestaque = 1;
						setFilmeFixado(movie);
					}
				});
				if (temDestaque == 0) {
					setFilmeFixado(res[0]);
				}
				console.log('b')
				setMovies(res);
			} else {
				let movies = [];
				let TemFixados = true;
				res.forEach((movie) => {
					//verifica se tem a chave "hasCategory" e se tem a chave "hasCategory" tem o valor "true"
					if (movie.hasCategory == true) {
						if (movie.descapa == 1) {
							TemFixados = false;
						}
						setFilmeFixado(movie);
						movies.push(movie);
					}
				});
				if (TemFixados) {
					//define o primeiro item como fixado
					movies[0].descapa = 1;
					setFilmeFixado(movies[0]);
				}
				setMovies(movies);
			}
		});
	}, []);

	const url = process.env.REACT_APP_URL;

	return (
		<>
			<FsLightbox
				toggler={toggler1}
				sources={[
					<iframe
						src={icon}
						title=" "
						width="500px"
						height="200px"
						frameBorder="0"
						allow="autoplay; fullscreen"
						allowFullScreen
					/>,
				]}
			/>
			<FsLightbox
				toggler={toggler2}
				sources={[
					<iframe
						src={icon}
						title=" "
						width="500px"
						height="200px"
						frameBorder="0"
						allow="autoplay; fullscreen"
						allowFullScreen
					/>,
				]}
			/>
			<FsLightbox
				toggler={toggler3}
				sources={[
					<iframe
						src={icon}
						title=" "
						width="500px"
						height="200px"
						frameBorder="0"
						allow="autoplay; fullscreen"
						allowFullScreen
					/>,
				]}
			/>

			<div className="main-content">
				<section id="home" className="iq-main-slider p-0 iq-rtl-direction dmobile">
					<div id="home-slider" className="slider m-0 p-0">
						<SwiperSlide
							className="slide slick-bg s-bg-1"
							style={{
								backgroundImage: `url(${url}/api/user/profile/${filmeFixado.desbanner})`,
							}}>
							<Container
								fluid
								className="position-relative h-100 container-wrapper">
								<div className="slider-inner h-100">
									<Row className="align-items-center  mt-10  pt-5 iq-ltr-direction">
										<Col xl="3" lg="3" md="3">
											<div className="text-primary title starring pt-5  ml-0">
												<h1 className="MovieText ml-0" >Cursos</h1>
											</div>
										</Col>
										<Col xl="9" lg="9" md="9">
											<div className="title starring pt-5  ml-0">
												<DropdownBtn currentName={currentCat} categories={cats} />
											</div>
										</Col>
										{/* <Accordion /> */}
									</Row>
									<Row className="mt-negative title-padding align-items-center special-height iq-ltr-direction">

										<Col xl="4" lg="12" md="12"  >

											<div className="text-primary title starring pb-3">
												<span className='movietag rounded pt-1 pb-1'>
													<span className="MovieText">EM ALTA</span>
												</span>
											</div>
											<h2
												className="slider-text big-title title"
												data-iq-gsap="onStart"
												data-iq-position-x="-100">
												{filmeFixado.desname}
											</h2>

										</Col>
									</Row>
								</div>
							</Container>
						</SwiperSlide>
					</div>
				</section>

				<div className="main-content">
					<section id="iq-suggestede" className="s-margin pb-5 ">
						<Container fluid>
							<h5 className="pb-5 pl-4 dmobile"><a href="#">Em Progresso</a><pipe className="pr-2 pl-2" style={{ opacity: .2 }}>|</pipe> <a href="#">Concluídos</a></h5>
							<Col xl="9" lg="9" md="9">
								<div className="title smobile starring text-center pt-2 pb-3  ml-0 pb-4">
									<DropdownBtn size="sm" currentName={currentCat} categories={cats} />
									<div id="myNav" class="overlay">
										<span class="closebtn" onClick={closeNav}><i class="fa fa-close"></i></span>
										<div class="overlay-content">
											{cats.map(
												(category) => (
														<Link to={`/${category.id}`}>
															{
																category.desname
															}
														</Link>
												)
											)}
										</div>
									</div>
									<span class="overlay-open-btn" onClick={openNav}>Todos <i class="fa fa-chevron-down"></i></span>
								</div>
							</Col>
							<Row>
								<Col className="cards-mec overflow-hidden">
									<div className="row list-inline favorites-slider row p-0 m-0 iq-rtl-direction">
										{movies
											.sort(() => Math.random() - 0.5)
											.slice(0, 4)
											.map((movie, index) => {
												if (
													match.params.id == null ||
													movie.descategory ==
													match.params.id
												) {
													return (
														<div className="dmobile  w-100 col-3 align-items-center text-center pb-2">
															<div className="col align-items-center " as="li">
																<Link to={`/movie-details/${movie.id}`}>
																	<div className="block-images position-relative">
																		<div className="img-box">
																			<img
																				src={`${url}/api/user/profile/${movie.desimage}`}
																				className="img-fluid"
																			/>
																		</div>
																	</div>
																</Link>
																<div style={{
																	/* border bottom with size 50% width */
																	borderBottom: "2px solid red",
																	width: "50%",
																	margin: "auto",
																}}></div>
															</div>
														</div>
													);
												}
											})}
										{movies
											.sort(() => Math.random() - 0.5)
											.slice(0, 4)
											.map((movie, index) => {
												if (
													match.params.id == null ||
													movie.descategory ==
													match.params.id
												) {
													return (
														<div className="smobile col-4 align-items-center text-center pb-4 bordercolor" as="li">
															<Link to={`/movie-details/${movie.id}`}>
																<div className="block-images position-relative " >
																	<div className="img-box">
																		<img
																			src={`${url}/api/user/profile/${movie.desimage}`}
																			className="img-fluid"
																		/>
																	</div>
																</div>
															</Link>
															<div style={{
																/* border bottom with size 50% width */
																borderBottom: "1px solid red",
																width: "50%",
																margin: "auto",
															}}></div>
														</div>
													);
												}
											})}
									</div>
								</Col>
							</Row>
						</Container>
					</section>
				</div>
			</div>
		</>
	);
};

export default Homepage;
