import React,{useEffect, useState} from 'react'
// import React,{useEffect,useState} from 'react'
import { Link,useLocation} from 'react-router-dom'
// import { Link} from 'react-router-dom'
import {Accordion,Button} from 'react-bootstrap'
import Scrollbar from 'smooth-scrollbar'

//img
import logo from '../../../../../src/assets/images/logo.png'

//Custom
import Routes from '../../../../router/admin/default'
import JsonWebToken from '../../../../router/auth/JsonWebToken'
import Logout from '../../../../views/backend/pages/auth/logout'
import Swal from "sweetalert2";


let _JsonWebToken = new JsonWebToken(localStorage.getItem("auth"));
let isLogged = _JsonWebToken.validateToken();

// function mapStateToProps(state) {
//     return {
//         darkMode: getDarkMode(state)
//     };
// }

const minisidbar =() =>{
    document.body.classList.toggle('sidebar-main')
}

const SidebarStyle = (props) => {
    
    //Collapse state
    const[activeMenu,setActiveMenu] = useState(false)
    const[activesubMenu,setSubmenu] = useState(false)
    const[activesubMenu1,setSubmenu1] = useState(false)

    // const[activesubMenu2,setSubmenu2] = useState(false)
    let [getRotas, setRotas] = useState([]);
    let _Rotas = new Routes();

    //location
    let location = useLocation();


    useEffect( () =>{
            Scrollbar.init(document.querySelector('#sidebar-scrollbar'))  
            _JsonWebToken.retrieveAllData(isLogged).then(_data => {
                // get routes from Routes
                setRotas(_Rotas.getRoutes( _data.data[0].desaccess ))
            }).catch(error => {
                // if user is not logged in
            })
    }, []);



    return(
        <>
            <div className="iq-sidebar">
                <div className="iq-sidebar-logo d-flex justify-content-between">
                    <Link to="/" className="header-logo">
                    <img src={logo} className="img-fluid rounded-normal" alt=""/>
                        {/* <div className="logo-title">
                            <span className="text-primary text-uppercase">Streamit</span>
                        </div> */}
                    </Link>
                    {/* <div className="iq-menu-bt-sidebar">
                        <div className="iq-menu-bt align-self-center">
                            <div className="wrapper-menu" onClick={minisidbar}>
                                <div className="main-circle"><i className="las la-bars"></i></div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="data-scrollbar" data-scroll="1" id="sidebar-scrollbar">
                    <nav className="iq-sidebar-menu">
                    <Accordion as="ul" id="iq-sidebar-toggle" className="iq-menu" onSelect={(e) => setActiveMenu(e)}>

                        {getRotas.map((item, index) => {
                             return (
                              !item.submenu ?
                              <li className={`${location.pathname === item.path ? 'active' : ''} `}>
                                   <Link to={item.path} className="iq-waves-effect">
                                       <i className={item.icon}></i>
                                       <span>{item.name}</span>
                                   </Link>
                               </li>
                                :
                                <li className={activeMenu === index ? 'active' : ''}>
                                    <Accordion.Toggle as={Button} href="#" eventKey={index} variant=" collapsed" data-toggle="collapse" aria-expanded="false"><i className="las la-list-ul"></i><span>{item.name}</span><i className="ri-arrow-right-s-line iq-arrow-right"></i></Accordion.Toggle>
                                        <Accordion.Collapse  className="submenu" eventKey={index}>
                                            <ul id={index} className="iq-submenu " data-parent="#iq-sidebar-toggle">
                                                {item.submenu.map((subitem, subindex) => {
                                                    return (
                                                        <li className={`${location.pathname === subitem.path ? 'active' : ''} `}><Link to={subitem.path}><i className={subitem.icon}></i>{subitem.name}</Link></li>
                                                    )
                                                })}
                                            </ul>
                                    </Accordion.Collapse>
                                </li>
                            )
                        })}

                      
                     
                    </Accordion>
                    </nav>
                </div>
            </div>
        </>
    )
}

export default SidebarStyle;
