import React,{useEffect, useState} from 'react'
import { Link} from 'react-router-dom'
import {Container,Row,Col,OverlayTrigger,Tooltip} from 'react-bootstrap'
import Card  from '../../../components/Card'
import Swal from 'sweetalert2'
//img
import user01 from '../../../assets/images/user/01.jpg'
import user05 from '../../../assets/images/user/05.jpg'
import user03 from '../../../assets/images/user/03.jpg'
import user08 from '../../../assets/images/user/08.jpg'
import user06 from '../../../assets/images/user/06.jpg'
import user10 from '../../../assets/images/user/10.jpg'

import UserTable from '../../../database/table/user'

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";


const TableUser = new UserTable()



const User = () => { 

    const [users,setUsers] = useState([])

    useEffect( () =>{

      TableUser.get().then(res => {
         setUsers(res)
      })

    }, []);

    //if table already loaded then destroy it
    if ($.fn.DataTable.isDataTable("#table")) {
        $("#table").DataTable().destroy();
    }
     
     if (users.length > 0) {
         console.log({
             data: users
         })
         const table = $("#table").DataTable({
             data: users,
             columns: [
                 { data: "id" },
                 { data: "desname" },
                 { data: "desemail" },
                 { data: "desaccess" },
                 { data: "dtupdate" },
             ],
             //style last column
             columnDefs: [
                 {
                        targets: 3,
                        render: function (data, type, row) {
                            return data == 1 ? "<span class='text-success'>Assinante</span>" : "<span class='text-danger'>Não Assinante</span>";
                        },
                        // targets: 5,
                        // render: function (data, type, row) {
                        //     let frow = JSON.stringify(row).replace(/"/g, "'");
                        //     //button with row object onclick
                        //     return `<button class="btn-sm btn-primary" onclick="EditCategory(${frow})">Editar</button>`;
                        // }

                 },
             ],
             //hide "shoe entries button"
             lengthChange: false,
             //change position of "search" input
             dom: 'lrtip',
         });
         // #column3_search is a <input type="text"> element
         $('#input-search-data').on( 'keyup', function () {
             //saerch from table by name in all columns
             table.search( this.value ).draw();
         } );

    }
      
 

    return (
       <> 
           <Container fluid>

                    <Card>
                        <Card.Header className="d-flex justify-content-between no-bottom-border" >
                            <Card.Header.Title>
                                <h4 className="card-title">Lista de Usuários</h4>
                            </Card.Header.Title>
                        </Card.Header>
                        <div className="datatable-input-holder">
                            <input type="text" className="form-control datatable-search" id="input-search-data" placeholder="Pesquisar" />
                        </div>
                        <Card.Body>
                        <div class="table-responsive p-0 pb-2">
                            <table id="table" className="table align-items-center justify-content-center mb-0">
                                <thead>
                                    <tr>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">ID</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Nome</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">E-mail</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Status</th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Registro</th>
                                     
                                    </tr>
                                </thead>
                                <tbody></tbody>
                            </table>
                        </div>
                        </Card.Body>
                    </Card>
                </Container>

       </>
    )
}
export default User;