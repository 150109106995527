import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
// rtl 
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Global Libs
import Swal from 'sweetalert2'
import axios from 'axios'

import logo from "../../../../assets/images/logo.png";

const mapStateToProps = (state) => {
    return {
        rtlMode: 'rtl'
    };
}
let rtlModeAction = 'rtl'

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        rtlModeAction
    },
        dispatch
    )
})




const TryLogin = (e) => {
    //hande submit
    e.preventDefault();
    //Save into Variables]
    let data = new FormData();
    data.append('desemail', e.target.desemail.value);
    data.append('despassword', e.target.despassword.value);
    //swal with loading screen
    Swal.fire({
        title: 'Autenticando...',
        icon: 'warning',
        html: 'Aguarde enquanto validamos os dados...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        didOpen: () => {
            Swal.showLoading()
            //Axios Call
            axios({
                method: 'post',
                url: process.env.REACT_APP_URL + '/api/auth/login',
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json'
                },
                data: data
            })
                .then(function (response) {
                    if (response.data) {
                        Swal.close()
                        //Save into LocalStorage
                        localStorage.setItem('auth', response.data.token)
                        //redirect to home with history
                        window.location.href = '/'
                    }
                })
                .catch(error => {
                    //Close Loading Modal
                    Swal.close();
                    //Show Error
                    Swal.fire({
                        title: 'Ocorreu um erro!',
                        text: error.response.data.message,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                })

        },
        allowOutsideClick: () => !Swal.isLoading()
    })
}












const Login = (props) => {

    return (
        <>
            <section className="sign-in-page">
                <Container>
                    <Row className="justify-content-center align-items-center height-self-center">
                        <Col lg="8" md="12" className="align-self-center">
                            <div className="sign-user_card ">
                                <div className="sign-in-page-data">
                                    <div className="sign-in-from w-100 m-auto">
                                        <img
                                            className="img-fluid logo"
                                            src={logo}
                                            alt="streamit"
                                        />
                                        <Form className="mt-4" onSubmit={TryLogin} >
                                            <label>
                                                <span className="form-label">E-mail</span>
                                            </label>
                                            <Form.Group>
                                                <Form.Control type="email" name="desemail" className="form-control mb-0" id="desemail" autoComplete="off" required />
                                            </Form.Group>
                                            <label>
                                                <span className="form-label">Senha</span>
                                            </label>
                                            <Form.Group>
                                                <Form.Control type="password" name="despassword" className="form-control mb-0" id="despassword" required />
                                            </Form.Group>
                                            <div className="d-flex links">
                                                <Link to="/auth/recover">Esqueceu sua senha?</Link>
                                            </div>
                                            <div className="sign-info">
                                                <Button type="submit" className="btn btn-hover btn-primary1">Entrar</Button>
                                            </div>
                                        </Form>
                                        <hr></hr>
                                        <div className="d-flex links">
                                            Faça seu cadastro e<br></br>conquiste o American Dream
                                        </div>
                                        <div className="sign-info">
                                            <Link to="/auth/register"><Button>Criar conta</Button></Link>
                                        </div>
                                        <div className="mt-3 d-flex links">
                                            • Precisa de ajuda?
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)