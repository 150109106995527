//router
import LayoutsRoute from './router/layouts-route';

import './assets/css/bootstrap.min.css'

import './dashbard_assets/css/typography.css'
import './dashbard_assets/css/style.css';

import './assets/scss/plugins/_sweet-alert.scss';
import './assets/css/typography.css'
import './assets/css/style.css';
import './assets/css/responsive.css'

function App() {
  return (
    <div className="App">
      <LayoutsRoute />
    </div>
  );
}

export default App;
